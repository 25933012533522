import { inject, Injectable } from '@angular/core';
import {
  IBoatAddMovementPayload,
  IBoatDepartureArrivalPayload,
  ITransferBookingPayload,
  PostBoatMovementBody,
} from '@dm-workspace/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of, switchMap } from 'rxjs';
import { MMS_BOAT_MOVEMENT_CONFIG_TOKEN } from '../tokens/mms-boat-movement.config';
import { MmsBoatMovementWarningConfirmModalComponent } from '../modals/mms-boat-movement-warning-confirm-modal/mms-boat-movement-warning-confirm-modal.component';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import { MmsBoatMovementFormVersion } from '../components/mms-boat-movement-form/mms-boat-movement-form.builder';
import {
  MmsBoatAddMovementModalComponent,
  MmsBoatDepartureModalComponent,
  MmsBoatReportArrivalModalComponent,
} from '../modals';
import { MmsTransferBookingModalComponent } from '../modals/mms-transfer-booking-modal/mms-transfer-booking-modal.component';

@Injectable({
  providedIn: 'root',
})
export class BoatMovementService {
  #boatMovementConfig = inject(MMS_BOAT_MOVEMENT_CONFIG_TOKEN);
  #boatsApiService = inject(MmsBoatsApiService);
  #modal = inject(NgbModal);

  public openBoatMovementModal({ boat, resourceId }: IBoatAddMovementPayload, marinaCode: string): Observable<boolean> {
    const modalRef = this.#modal.open(MmsBoatAddMovementModalComponent, {
      size: 'lg',
    });
    const modalComponent = modalRef.componentInstance as MmsBoatAddMovementModalComponent;
    modalComponent.boatId = boat.id;
    modalComponent.resourceId = resourceId;
    modalComponent.formVersion = this.getMovementFormVersion(marinaCode);

    return modalRef.closed;
  }
  public openDepartureBoatModal({ boatId, resourceId, berthName }: IBoatDepartureArrivalPayload) {
    const modal = this.#modal.open(MmsBoatDepartureModalComponent, {
      size: 'md',
    });
    const modalComponent = modal.componentInstance as MmsBoatDepartureModalComponent;
    modalComponent.boatId = boatId;
    modalComponent.resource = {
      id: resourceId,
      name: berthName,
    };
    return modalComponent.confirmed;
  }
  public openArrivalBoatModal({ boatId, resourceId, berthName }: IBoatDepartureArrivalPayload) {
    const modal = this.#modal.open(MmsBoatReportArrivalModalComponent, {
      size: 'md',
    });
    const modalComponent = modal.componentInstance as MmsBoatReportArrivalModalComponent;
    modalComponent.boatId = boatId;
    modalComponent.resource = {
      id: resourceId,
      name: berthName,
    };
    return modalComponent.confirmed;
  }
  public openTransferBookingModal({ boatId, resource, toDate, reservationId, fromDate }: ITransferBookingPayload) {
    const modalRef = this.#modal.open(MmsTransferBookingModalComponent, {
      size: 'lg',
    });
    const modalComponent = modalRef.componentInstance as MmsTransferBookingModalComponent;
    modalComponent.boatId = boatId;
    modalComponent.toDate = toDate;
    modalComponent.fromDate = fromDate;
    modalComponent.reservationId = reservationId;
    modalComponent.resource = resource;

    return modalRef.closed;
  }
  getMovementFormVersion(marinaCode: string): MmsBoatMovementFormVersion {
    if (
      this.#boatMovementConfig.marinasForNewFormVersion === '*' ||
      this.#boatMovementConfig.marinasForNewFormVersion.includes(marinaCode)
    ) {
      return MmsBoatMovementFormVersion.NEW;
    }
    return MmsBoatMovementFormVersion.OLD;
  }

  validAndAcceptMovement(boatId: string, body: PostBoatMovementBody): Observable<boolean> {
    return this.#boatsApiService.verifyMovement(boatId, body).pipe(
      switchMap((warnings) => {
        if (!warnings?.length) {
          return of(true);
        }

        const modal = this.#modal.open(MmsBoatMovementWarningConfirmModalComponent, {
          size: 'md',
        });
        const modalInstance = modal.componentInstance as MmsBoatMovementWarningConfirmModalComponent;
        modalInstance.warningsKeys = warnings;
        return modalInstance.confirmed;
      })
    );
  }
}
