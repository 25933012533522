import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import { FormUtils } from '@dm-workspace/forms';
import { catchError, throwError } from 'rxjs';
import { NotificationService } from '@dm-workspace/notification';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';
import { finalize, tap } from 'rxjs/operators';
import { BerthAvailabilityReservationDto, MmsChangeMovementGapBody } from '@dm-workspace/types';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'dm-mms-boat-movement-change-gap-modalt',
  templateUrl: './mms-boat-movement-change-gap-modal.component.html',
  styleUrls: ['./mms-boat-movement-change-gap-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsBoatMovementChangeGapModalComponent {
  #aModal = inject(NgbActiveModal);
  #boatsApiService = inject(MmsBoatsApiService);
  #notificationService = inject(NotificationService);

  protected readonly formSubmitting = signal<boolean>(false);
  readonly form = new FormGroup({
    gapStart: new FormControl<string>(null, { validators: [Validators.required] }),
    gapEnd: new FormControl<string>(null, { validators: [Validators.required] }),
  });
  readonly movementId = signal<string>(null);
  readonly reservation = signal<BerthAvailabilityReservationDto>(null);

  protected submitForm() {
    if (this.formSubmitting() || !FormUtils.isValid(this.form)) {
      return;
    }
    this.formSubmitting.set(true);

    const dto: MmsChangeMovementGapBody = this.form.getRawValue();

    this.#boatsApiService
      .changeMovementGap(this.movementId(), dto)
      .pipe(
        catchError((res: HttpErrorResponse) => {
          this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
          return throwError(() => res);
        }),
        finalize(() => this.formSubmitting.set(false)),
        tap(() => {
          this.#notificationService.openSuccess();
          this.#aModal.close(true);
        })
      )
      .subscribe();
  }

  protected closeModal() {
    this.#aModal.close(false);
  }
}
