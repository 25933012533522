import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fiscalCodeValidator } from '@dm-workspace/forms';
import { Injectable } from '@angular/core';
import { removeEmptyString } from '@dm-workspace/utils';
import { Contact, CorporateType, CountryCode, CustomerType } from '@dm-workspace/types';
import { CustomertaxExemptNumberService } from './customer-tax-reference-id.service';
import { ProfileFormValue } from './profile-form.service';
import { CustomerUtils } from '../../../../utils/src/lib/customer.utils';

export type ProfileDocumentsForm = Partial<
  FormGroup<{
    taxExemptNumber?: FormControl<string>;
    fiscalCode?: FormControl<string>;
    passport?: FormControl<string>;
  }>
> &
  FormGroup;
export type ProfileDocumentsFormValue = ReturnType<ProfileDocumentsForm['getRawValue']>;
export type ProfileDocumentsOptions = {
  countryCode?: CountryCode;
  marinaCode?: CountryCode;
  customerType?: CustomerType;
  corporateType?: CorporateType;
};

@Injectable({
  providedIn: 'root',
})
export class ProfileDocumentsFormService {
  public customerTaxExemptNumberService = new CustomertaxExemptNumberService();

  public createForm(
    initValue?: Partial<ProfileDocumentsFormValue>,
    options?: ProfileDocumentsOptions
  ): ProfileDocumentsForm {
    const form = new FormGroup({});
    this.changeForm(form, options, initValue);
    return form;
  }

  public changeForm(
    form: ProfileDocumentsForm,
    options?: ProfileDocumentsOptions,
    initValue?: Partial<ProfileDocumentsFormValue>
  ) {
    if (options?.marinaCode) {
      this.changeFormByCountryCodeAndMarinaCode(form, options, initValue);
    } else {
      this.changeFormByCountryCode(form, options, initValue);
    }
  }

  private changeFormByCountryCodeAndMarinaCode(
    form: ProfileDocumentsForm,
    options: ProfileDocumentsOptions,
    initValue?: Partial<ProfileDocumentsFormValue>
  ) {
    const { countryCode, marinaCode } = options || {};
    this.customerTaxExemptNumberService.changeByCountryCodeAndMarinaCode(form, countryCode, marinaCode, initValue);
    this.changePassportByCountryCodeAndMarinaCode(form, countryCode, marinaCode, initValue);
    this.changeFiscalCodeByCountryCodeAndMarinaCode(form, options, initValue);
  }

  private changeFormByCountryCode(
    form: ProfileDocumentsForm,
    options?: ProfileDocumentsOptions,
    initValue?: Partial<ProfileDocumentsFormValue>
  ) {
    const { countryCode } = options || {};
    this.customerTaxExemptNumberService.changeByCountryCode(form, countryCode, initValue);
    this.changePassportByCountryCode(form, countryCode, initValue);
    this.changeFiscalCodeByCountryCode(form, options, initValue);
  }

  private changePassportByCountryCodeAndMarinaCode(
    form: ProfileDocumentsForm,
    countryCode: CountryCode,
    marinaCode: CountryCode,
    initValue?: Partial<ProfileDocumentsFormValue>
  ) {
    if (CustomerUtils.isPassportIdRequired(countryCode, marinaCode)) {
      const passportControl = new FormControl(initValue?.passport, {
        validators: [Validators.required, Validators.minLength(5)],
        updateOn: 'blur',
      });
      form.addControl('passport', passportControl);
    } else {
      form.removeControl('passport');
    }
  }

  private changePassportByCountryCode(
    form: ProfileDocumentsForm,
    countryCode: CountryCode, // For future feature
    initValue: Partial<ProfileDocumentsFormValue> = {}
  ) {
    const passportControl = new FormControl(initValue?.passport, {
      validators: [Validators.minLength(5)],
      updateOn: 'blur',
    });
    form.addControl('passport', passportControl);
  }

  private changeFiscalCodeByCountryCode(
    form: ProfileDocumentsForm,
    options?: ProfileDocumentsOptions,
    initValue?: Partial<ProfileDocumentsFormValue>
  ) {
    if (options?.countryCode === CountryCode.Italy) {
      form.addControl('fiscalCode', this.createFiscalCodeControl(initValue?.fiscalCode, options));
    } else {
      form.removeControl('fiscalCode');
    }
  }

  private changeFiscalCodeByCountryCodeAndMarinaCode(
    form: ProfileDocumentsForm,
    options?: ProfileDocumentsOptions,
    initValue?: Partial<ProfileDocumentsFormValue>
  ) {
    const { countryCode, marinaCode } = options;
    if (countryCode === CountryCode.Italy && marinaCode === CountryCode.Italy) {
      const validators = this.createFiscalCodeValidators(options);
      const fiscalCodeControl = new FormControl(initValue?.fiscalCode, {
        validators,
        updateOn: 'blur',
      });
      form.addControl('fiscalCode', fiscalCodeControl);
    } else {
      form.removeControl('fiscalCode');
    }
  }

  public createFiscalCodeControl(initValue?: string, options?: ProfileDocumentsOptions) {
    const validators = this.createFiscalCodeValidators(options);
    return new FormControl(initValue, {
      validators,
    });
  }

  private createFiscalCodeValidators({ corporateType, customerType }: ProfileDocumentsOptions = {}) {
    if (
      (corporateType && corporateType !== CorporateType.INDIVIDUAL) ||
      (customerType && customerType !== CustomerType.INDIVIDUAL)
    ) {
      return [Validators.required, Validators.minLength(11), Validators.maxLength(11)];
    }
    return [Validators.required, fiscalCodeValidator()];
  }

  public transformFormValueToPayload(formValue: ProfileDocumentsFormValue) {
    if (formValue.fiscalCode) {
      formValue.fiscalCode = formValue.fiscalCode.toUpperCase();
    }
    return removeEmptyString(formValue);
  }

  public transformCustomerContactToFormValue(contact: Contact): Partial<ProfileFormValue> {
    return {
      ...contact,
      fiscalCode: contact.customer?.fiscalCode,
    };
  }
}
