import { ChangeDetectionStrategy, Component, forwardRef, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import { IBoatDto } from '@dm-workspace/types';
import { Observable } from 'rxjs';
import { CustomSearchComponent } from '../customeSeach.class';

@Component({
  selector: 'dm-form-search-boat',
  templateUrl: './search-boat.component.html',
  styleUrl: './search-boat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBoatComponent),
      multi: true,
    },
  ],
})
export class SearchBoatComponent extends CustomSearchComponent<IBoatDto> {
  fetchItem(id: string): Observable<IBoatDto> {
    return this.#boatApiService.fetchById(id);
  }
  #boatApiService = inject(MmsBoatsApiService);
  fetchItems(search: string): Observable<IBoatDto[]> {
    return this.#boatApiService.searchBoats({ search, limit: 200 });
  }
}
