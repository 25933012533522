import { shareReplay, tap } from 'rxjs';
import { ChangeDetectorRef, Directive, ElementRef, Input, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapPolygon } from '@angular/google-maps';
import { IBerthDto, Marina, PolygonPoint } from '@dm-workspace/types';
import { MapService } from './services/map-service.service';
import { mapOptions, piersOptions } from './const';
import { Router } from '@angular/router';

const INFO_WINDOW_TIMEOUT = 300;
const INFO_WINDOW_TIMEOUT_SHORT = 15;

export function getPolygonBounds(polygon: MapPolygon): google.maps.LatLngBounds {
  const bounds = new google.maps.LatLngBounds();
  polygon.getPath().forEach((element, index) => bounds.extend(element));

  return bounds;
}

export type ActiveBerth<B = IBerthDto> = {
  polygon?: MapPolygon;
  berth: B;
  lat?: google.maps.LatLng;
  group?: number;
};

@Directive()
export class MapComponentBase<B = IBerthDto> {
  @Input() dynamicHeight = true;
  @Input() minHeight = 200;

  @ViewChild(GoogleMap) public map: GoogleMap;
  @ViewChild('clickInfoWindow', { static: false }) infoWindow: MapInfoWindow;
  @ViewChild('hoverInfoWindow', { static: false }) hoverInfoWindow: MapInfoWindow;

  readonly DRAWER_SPACING = 720;

  public isLoaded$ = this.mapService.init(mapOptions).pipe(
    tap(() => this.onMapLoad()),
    shareReplay(1)
  );
  public piers?: PolygonPoint[][] = [];
  public center: google.maps.LatLngLiteral;
  public berths?: Array<B> = [];
  public selectedMarina: Marina;
  public piersOptions = piersOptions;
  public selectedBerth?: ActiveBerth<B>;
  public hoveredBerth?: ActiveBerth<B>;
  public hoverTimeout: NodeJS.Timeout;
  public closeTimeout: NodeJS.Timeout;
  public selectedAlertId: string;

  protected berthNameInfoWindow?: google.maps.InfoWindow;

  get height(): string {
    if (this.dynamicHeight && this.el) {
      const { y } = this.el.nativeElement.getBoundingClientRect();
      const height = window.innerHeight - y;

      return (height > this.minHeight ? height : this.minHeight) + 'px';
    }

    return '100%';
  }

  constructor(
    protected mapService: MapService,
    protected cd: ChangeDetectorRef,
    protected el?: ElementRef,
    protected router?: Router
  ) {}

  protected onMapLoad(): void {
    return;
  }

  protected updateMarina() {
    if (!this.selectedMarina) {
      this.piers = [];
      return;
    }
    if (this.selectedMarina.layout?.center) {
      const [lng, lat] = this.selectedMarina.layout.center;
      this.center = { lng, lat };
    }
    this.piers = this.selectedMarina.layout?.coordinates;
    this.cd.markForCheck();
    // this.piers = this.getLayotFromJson();
  }

  protected setMapBoundsAndCenter(latLngArray: [number, number][], padding: number | google.maps.Padding = 0) {
    if (!this.map?.googleMap || !latLngArray) {
      return;
    }
    this.mapService.setMapBoundsAndCenter(this.map.googleMap, latLngArray, padding);
  }

  getPolygonCenter(polygon: MapPolygon): google.maps.LatLng {
    return getPolygonBounds(polygon).getCenter();
  }

  //TODO: This is used in MapMmiComponent and could be potentially moved there
  public onBerthPolygonClick(polygon: MapPolygon, berth: B) {
    this.selectedBerth = undefined;
    this.clearSelectedAlert();
    this.closeInfoWindowWithBerthName();
    this.closeHoverInfoWindow(0);
    this.handleBerthMouseEvent(polygon, berth, false);
  }

  public onBerthPolygonHover(polygon: MapPolygon, berth: B) {
    if (this.hoverInfoWindow) {
      this.closeHoverInfoWindow();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!this.selectedBerth || (this.selectedBerth.berth as any)?.berthId !== (berth as any).berthId) {
      this.handleBerthMouseEvent(polygon, berth, true);
    }
  }

  protected clearSelectedAlert(): void {
    if (this.selectedAlertId) {
      this.selectedAlertId = undefined;
      this.router.navigate([]);
    }
  }

  protected handleBerthMouseEvent(berthM: MapPolygon, berth: B, hover?: boolean) {
    const lat = this.getPolygonCenter(berthM);
    const active = {
      polygon: berthM,
      lat,
      berth,
    };

    if (!hover) {
      this.selectedBerth = active;
      this.map.googleMap.panToBounds(getPolygonBounds(berthM), { left: this.DRAWER_SPACING });
      this.openInfoWindow();
    } else {
      this.hoveredBerth = active;
      this.openHoverInfoWindow();
    }
  }

  openInfoWindow() {
    setTimeout(() => this.infoWindow?.open(), INFO_WINDOW_TIMEOUT_SHORT);
  }

  openHoverInfoWindow(noDelay?: boolean): void {
    clearTimeout(this.closeTimeout);
    this.hoverTimeout = setTimeout(
      () => this.hoverInfoWindow?.open(),
      noDelay ? INFO_WINDOW_TIMEOUT_SHORT : INFO_WINDOW_TIMEOUT
    );
  }

  protected closeInfoWindow() {
    this.infoWindow?.close();
  }

  protected closeHoverInfoWindow(timeout = INFO_WINDOW_TIMEOUT) {
    clearTimeout(this.closeTimeout);
    clearTimeout(this.hoverTimeout);

    this.closeTimeout = setTimeout(() => this.hoverInfoWindow?.close(), timeout);
  }

  protected closeInfoWindowWithBerthName() {
    this.berthNameInfoWindow?.close();
  }

  protected getMouseOverPopupContent(berthName: string, berthId?: string) {
    return `<strong>${berthName}</strong>`;
  }

  protected openInfoWindowWithBerthName(berthM: MapPolygon, berthName: string, berthId?: string) {
    if (google && this.map.googleMap) {
      this.closeInfoWindowWithBerthName();
      const position = this.getPolygonCenter(berthM);

      this.berthNameInfoWindow = new google.maps.InfoWindow({
        content: this.getMouseOverPopupContent(berthName, berthId),
        position,
      });

      this.berthNameInfoWindow.open({
        map: this.map.googleMap,
      });
    }
  }
}
