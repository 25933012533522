<form [formGroup]="form">
  <div class="modal-header">
    <h2 class="title">
      @switch (type) {
        @case ('enquiry') {
          {{ "CANCEL_THE_ENQUIRY" | translate }}
        }
        @case ('offer') {
          {{ "OFFER_CANCELLATION" | translate }}
        }
      }
    </h2>
  </div>
  <div class="modal-body">
    <p class="mb-3"><strong>{{"SELECT_OFFER_CANCELLATION_REASON" | translate}}</strong></p>

    <dm-form-group class="mb-3" *ngFor="let reason of QuoteCancelReason | keyvalue : NgForUtils.originalOrder; let index = index">
      <input dmForm type="radio" formControlName="reason" [id]="'reason_' + index" [value]="reason.value">
      <label [for]="'reason_' + index">{{ reason | quoteCancelReasonTranslateKey | translate }}</label>
    </dm-form-group>

    <dm-form-group class="mt-3">
      <input dmForm type="radio" formControlName="reason" [id]="'reason_other'" [value]="'other'">
      <label [for]="'reason_other'">{{ "OTHER" | translate }}</label>
    </dm-form-group>

    <dm-form-group class="mt-2 ms-4">
      <input dmForm formControlName="otherReason" [placeholder]="'TYPE_REASON_HERE' | translate">
      <dm-form-error-message controlName="otherReason"/>
    </dm-form-group>

    <dm-form-error-message controlName="reason"/>
  </div>

  <div class="modal-footer justify-content-between d-flex">
    <button type="button" class="btn btn-secondary-negative" (click)="onCancel()">
      {{ "CANCEL" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="buttonConfirmClick()" [disabled]="apiCalling()">
      <dm-ui-spinner class="me-1" [showSpinner]="apiCalling()" />
      {{ "CANCEL_OFFER" | translate }}
    </button>
  </div>
</form>
