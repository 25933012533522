/// <reference types="@types/google.maps" />
import {
  BerthAlertStatusInMarina,
  BerthOccupancyStatusInMarina,
  BerthReservationsStatusInMarina,
  BerthStatusInMarina,
  MarinaMapColorStatus,
} from '@dm-workspace/types';

export const defaultBerthOptions: google.maps.PolygonOptions = {
  strokeColor: '#8a8a8a',
  strokeOpacity: 1,
  strokeWeight: 1,
  zIndex: 2,
  draggable: false,
  fillColor: '#FFFFFF',
  fillOpacity: 1,
  editable: false,
};
export const multihullOptions: google.maps.PolygonOptions = {
  strokeColor: '#8a8a8a',
  strokeOpacity: 0,
  strokeWeight: 1,
  zIndex: 1,
  draggable: false,
  fillColor: '#FFFFFF',
  fillOpacity: 0.6,
  editable: false,
};
export const markerSVG =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='25' viewBox='0 0 31 25'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='84.347%25' x2='1.705%25' y1='46.855%25' y2='56.68%25'%3E%3Cstop offset='0%25' stop-opacity='0'/%3E%3Cstop offset='100%25' stop-opacity='.547'/%3E%3C/linearGradient%3E%3Cfilter id='b' width='100%25' height='100%25' x='0%25' y='0%25' filterUnits='objectBoundingBox'%3E%3CfeGaussianBlur in='SourceGraphic'/%3E%3C/filter%3E%3ClinearGradient id='c' x1='-7.633%25' x2='150.197%25' y1='0%25' y2='134.408%25'%3E%3Cstop offset='0%25' stop-color='%23AF452F'/%3E%3Cstop offset='52.803%25' stop-color='%23EF9D85'/%3E%3Cstop offset='100%25' stop-color='%23AD4D2C'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='url(%23a)' d='M9.36 24.12l24.48-2.16-4.68-9z' filter='url(%23b)' style='mix-blend-mode:multiply'/%3E%3Cpath fill='url(%23c)' d='M18.343 8.731c-.29-4.699-4.086-8.492-8.72-8.72A9.203 9.203 0 0 0 9.18 0C4.11 0 0 4.168 0 9.31a9.37 9.37 0 0 0 1.37 4.895l6.892 9.38a1.1 1.1 0 0 0 1.892 0l6.837-9.38a9.37 9.37 0 0 0 1.352-5.474z'/%3E%3Ccircle cx='9.36' cy='9.36' r='3.6' fill='%23FFF'/%3E%3C/g%3E%3C/svg%3E%0A";

export const berthsColors: Record<MarinaMapColorStatus, google.maps.PolygonOptions> = {
  [MarinaMapColorStatus.white]: {
    fillColor: '#FFFFFF',
    strokeColor: '#8a8a8a',
  },
  [MarinaMapColorStatus.lightGreen]: {
    fillColor: '#00DD73',
    strokeColor: '#03A400',
  },
  [MarinaMapColorStatus.green]: {
    fillColor: '#1AAF29',
    strokeColor: '#00DD73',
  },
  [MarinaMapColorStatus.yellow]: {
    fillColor: '#FFEAB5',
    strokeColor: '#E5B53D',
  },
  [MarinaMapColorStatus.orange]: {
    fillColor: '#E5B53D',
    strokeColor: '#b0851a',
  },
  [MarinaMapColorStatus.darkOrange]: {
    fillColor: '#F58A0F',
    strokeColor: '#b75606',
  },
  [MarinaMapColorStatus.lightBlue]: {
    fillColor: '#A9DFFF',
    strokeColor: '#478BB2',
  },
  [MarinaMapColorStatus.blue]: {
    fillColor: '#AACCF8',
    strokeColor: '#126CEA',
  },
  [MarinaMapColorStatus.darkBlue]: {
    fillColor: '#041E42',
    strokeColor: '#041E42',
  },
  [MarinaMapColorStatus.purple]: {
    fillColor: '#7F00FF',
    strokeColor: '#4b039f',
  },

  [MarinaMapColorStatus.red]: {
    fillColor: '#FF6161',
    strokeColor: '#D90000',
  },
  [MarinaMapColorStatus.gray]: {
    fillColor: '#bdbdbd',
    strokeColor: '#7c7c7c',
  },
  [MarinaMapColorStatus.gray500]: {
    fillColor: '#A4ACB6',
    strokeWeight: 2,
    strokeColor: '#CDD0D4',
  },
  [MarinaMapColorStatus.darkGray]: {
    fillColor: '#636363',
    strokeColor: '#3b3b3b',
  },
  [MarinaMapColorStatus.black]: {
    fillColor: '#000',
    strokeColor: '#262626',
  },
  [MarinaMapColorStatus.lightGray]: {
    fillColor: '#ECF5FE',
    strokeColor: '#506A8D',
  },
};
export const getBerthColorByStatus = (status: BerthStatusInMarina): MarinaMapColorStatus => {
  switch (status) {
    case BerthOccupancyStatusInMarina.overOccupied:
    case BerthAlertStatusInMarina.ongoingAlert:
    case BerthReservationsStatusInMarina.overbooking:
      return MarinaMapColorStatus.red;
    case BerthOccupancyStatusInMarina.occupied:
      return MarinaMapColorStatus.green;
    case BerthOccupancyStatusInMarina.expectedArrivals:
    case BerthReservationsStatusInMarina.reservedMidTerm:
      return MarinaMapColorStatus.orange;

    case BerthOccupancyStatusInMarina.expectedDepartures:
      return MarinaMapColorStatus.purple;

    case BerthReservationsStatusInMarina.reservedTransient:
    case BerthAlertStatusInMarina.inProgressAlert:
      return MarinaMapColorStatus.lightBlue;

    case BerthReservationsStatusInMarina.reservedLongTerm:
      return MarinaMapColorStatus.darkBlue;
  }
  return MarinaMapColorStatus.white;
};
// export const berthsColorsBoatMovement: Record<BerthStatusInMarina, google.maps.PolygonOptions> = {
//   [BerthReservationsStatusInMarina.available]: {
//     fillColor: '#FFFFFF',
//     strokeColor: '#1B253A',
//   },
//   // [BerthStatusInMarina.disabled]: {
//   //   fillColor: '#1B253A',
//   //   strokeColor: '#1B253A',
//   // },
// };
export const mergePolygonOptionsWithColor = (color: MarinaMapColorStatus): google.maps.PolygonOptions => {
  return {
    ...defaultBerthOptions,
    ...berthsColors[color],
  };
};

export const piersOptions: google.maps.PolygonOptions = {
  strokeWeight: 0,
  fillColor: '#f4f1ea',
  fillOpacity: 1,
  clickable: false,
};
export const mapOptions: google.maps.MapOptions = {
  zoom: 17,
  streetViewControl: false,
  mapTypeControl: false,
};
