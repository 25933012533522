<div class="row gx-4 row-cols-6 gy-4 gx-4">
  <dm-form-input-value>
    <ng-container header>{{'BOAT_NAME' | translate}}</ng-container>
    <ng-container body>{{boat?.name}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{'BOAT_TYPE' | translate}}</ng-container>
    <ng-container body>{{boat?.type}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{'REGISTRATION_NUMBER' | translate}}</ng-container>
    <ng-container body>{{boat?.registrationNumber}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{'FLAG' | translate}}</ng-container>
    <ng-container body>{{boat?.flag}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{'PORT_OF_REGISTRATION' | translate}}</ng-container>
    <ng-container body>{{boat?.portOfRegistration}}</ng-container>
  </dm-form-input-value>

  <dm-form-input-value>
    <ng-container header>{{'BOAT_USAGE' | translate}}</ng-container>
    <ng-container body>{{boat?.usage}}</ng-container>
  </dm-form-input-value>
</div>

<div class="mt-4">
  <div class="gy-3 row row-cols-6">
    <dm-form-input-value>
      <ng-container header>{{'LENGTH_M' | translate}}</ng-container>
      <ng-container body>{{boat?.length}}</ng-container>
    </dm-form-input-value>

    <dm-form-input-value>
      <ng-container header>{{'WIDTH_M' | translate}}</ng-container>
      <ng-container body>{{boat?.maxBeam}}</ng-container>
    </dm-form-input-value>

    <dm-form-input-value>
      <ng-container header>{{'DRAFT_M' | translate}}</ng-container>
      <ng-container body>{{boat?.draft}}</ng-container>
    </dm-form-input-value>
  </div>
</div>

<div class="mt-4">
  <div class="gy-3 row row-cols-6">
    <dm-form-input-value>
      <ng-container header>{{'BRAND' | translate}}</ng-container>
      <ng-container body>{{boat?.brand?.name}}</ng-container>
    </dm-form-input-value>

    <dm-form-input-value class="col-4">
      <ng-container header>{{'VSS_CODE' | translate}}</ng-container>
      <ng-container body>{{boat?.vssNumber}}</ng-container>
    </dm-form-input-value>
  </div>
</div>
