import { FormControl, FormGroup } from '@angular/forms';
import {
  ProfileDetailsForm,
  ProfileDetailsFormService,
  ProfileDocumentsForm,
  ProfileDocumentsFormService,
  ProfileFormService,
} from '@dm-workspace/shared';
import { Injectable } from '@angular/core';
import { CorporateType, CountryCode, Customer, CustomerType } from '@dm-workspace/types';
import { FormUtils } from '@dm-workspace/forms';

export type MmsCustomerForm = FormGroup<
  ProfileDetailsForm['controls'] &
    ProfileDocumentsForm['controls'] & {
      email: FormControl<string>;
      corporateType: FormControl<CorporateType>;
    }
>;
export type MmsCustomerFormValue = ReturnType<MmsCustomerForm['getRawValue']>;

@Injectable({
  providedIn: 'root',
})
export class MmsCustomerFormService {
  constructor(
    private profileFormService: ProfileFormService,
    private profileDetailsFormService: ProfileDetailsFormService,
    private profileDocumentsFormService: ProfileDocumentsFormService
  ) {}

  public createForm(
    initValue?: Partial<MmsCustomerFormValue>,
    options?: {
      countryCode?: CountryCode;
      marinaCode?: CountryCode;
      customerType?: CustomerType;
      corporateType?: CorporateType;
    }
  ): MmsCustomerForm {
    const form = this.profileFormService.createForm(initValue, options);
    const emailControl = this.profileDetailsFormService.createEmailControl(initValue?.email);
    form.addControl('email', emailControl);

    const corporateTypeControl = this.profileDetailsFormService.createTypeControl(initValue?.corporateType);
    if (!initValue?.corporateType) {
      corporateTypeControl.setValue(CorporateType.INDIVIDUAL);
    }
    corporateTypeControl.disable();
    form.addControl('corporateType', corporateTypeControl);

    return form as unknown as MmsCustomerForm;
  }

  public transformFormValueToPayload(formValue: MmsCustomerFormValue): MmsCustomerFormValue {
    const transformedFormValue = {
      ...this.profileDetailsFormService.transformFormValueToPayload(formValue),
      ...this.profileDocumentsFormService.transformFormValueToPayload(formValue),
    };
    return FormUtils.emptyStringToNull(transformedFormValue);
  }

  transformCustomerToFormValue(customer: Partial<Customer>): Partial<MmsCustomerFormValue> {
    return {
      ...customer,
      state: customer.state ?? customer.stateOrProvince?.name,
      passport: customer?.passport ?? customer?.contact?.passport,
    };
  }
}
