import { inject, Injectable } from '@angular/core';
import { ENV, MarinaCMS } from '@dm-workspace/types';
import { HttpClient } from '@angular/common/http';
import { ApiCmsResponse } from '../../../../types/src/libs/interfaces/api-cms.interface';

@Injectable({
  providedIn: 'root',
})
export class CmsMarinasApiService {
  protected readonly env = inject(ENV);
  #apiUrl = `${this.env.cmsApiUrl}marinas`;
  #httpClient = inject(HttpClient);
  list() {
    return this.#httpClient.get<ApiCmsResponse<MarinaCMS[]>>(this.#apiUrl, {
      params: {
        'populate%5Bfields%5D%5B0%5D': ['berthSettings'],
        'populate%marinaCountry%5D%5Bfields%5D%5B0%5D': ['slug', 'name'],
      },
    });
  }
  getById(id: string) {
    return this.#httpClient.get<ApiCmsResponse<MarinaCMS>>(`${this.#apiUrl}/${id}`, {
      params: {
        'populate[berthSettings]': '*',
        'populate[rank]': '*',
        'populate[contact]': '*',
        'populate[customerReviews]': '*',
        'populate[mediaThumbnails][populate][images][populate]': '*',
        'populate[marinaCountry][populate]': '*',
      },
    });
  }
}
