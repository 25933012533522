import { ChangeDetectionStrategy, Component, effect, EventEmitter, input, Input, Output, signal } from '@angular/core';
import {
  BerthAlertStatusInMarina,
  BerthOccupancyStatusInMarina,
  BerthReservationsStatusInMarina,
  BerthStatusInMarina,
} from '@dm-workspace/types';
import { MapViewTypes } from '@dm-workspace/utils';
import { fromRightAnimation } from '@dm-workspace/shared';

type MApViewWithoutStatuses = Exclude<MapViewTypes, MapViewTypes.statuses>;

@Component({
  selector: 'dm-map-dynamic-legend',
  templateUrl: './map-dynamic-legend.component.html',
  styleUrls: ['./map-dynamic-legend.component.scss'],
  animations: [fromRightAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapDynamicLegendComponent {
  constructor() {
    effect((onCleanup) => {
      const viewType = this.viewType();
      if (viewType === MapViewTypes.statuses) {
        return;
      }
      this.filters = this.filtersByView[viewType];
      if (this.selectedFilters === undefined) {
        this.selectedFilters = new Set(this.filters);
      }
      this.emitValue();
    });
  }

  showLegend = signal(false);
  protected selectedFiltersMap: Record<MApViewWithoutStatuses, Set<BerthStatusInMarina> | undefined> = {
    [MapViewTypes.alerts]: undefined,
    [MapViewTypes.occupancy]: undefined,
    [MapViewTypes.reservations]: undefined,
  };
  get selectedFilters() {
    const viewType = this.viewType();
    if (viewType === MapViewTypes.statuses) {
      return;
    }
    return this.selectedFiltersMap[viewType];
  }
  set selectedFilters(set: Set<BerthStatusInMarina>) {
    const viewType = this.viewType();
    if (viewType === MapViewTypes.statuses) {
      return;
    }
    this.selectedFiltersMap[viewType] = set;
  }
  protected filtersByView: Record<MApViewWithoutStatuses, BerthStatusInMarina[]> = {
    [MapViewTypes.alerts]: Object.values(BerthAlertStatusInMarina),
    [MapViewTypes.occupancy]: Object.values(BerthOccupancyStatusInMarina),
    [MapViewTypes.reservations]: Object.values(BerthReservationsStatusInMarina),
  };
  @Input() selectAllOnStart = true;
  viewType = input.required<MapViewTypes>();
  @Output() filtersChange: EventEmitter<BerthStatusInMarina[]> = new EventEmitter();
  protected filters: BerthStatusInMarina[] = [];
  toggleShowLegend() {
    this.showLegend.update((value) => !value);
  }

  emitValue() {
    this.filtersChange.emit(Array.from(this.selectedFilters));
  }

  toggleFilter(status: BerthStatusInMarina) {
    const viewType = this.viewType();
    if (viewType !== MapViewTypes.statuses) {
      if (this.selectedFilters.has(status)) {
        this.selectedFilters.delete(status);
      } else {
        this.selectedFilters.add(status);
      }
      this.emitValue();
    }
  }
  resetFilters() {
    this.selectedFilters = new Set(this.filters);
    this.emitValue();
  }
  get hasSomeFiltersUncheck(): boolean {
    return this.selectedFilters?.size < this.filters.length;
  }
}
