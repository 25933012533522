import { Inject, Injectable } from '@angular/core';
import { ENV } from '@dm-workspace/types';
import { IEnvironment } from '@dm-workspace/environments';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  private isInjected: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(ENV) private env: IEnvironment,
    private router: Router
  ) {}

  public inject(key: string) {
    if (this.isInjected) {
      return;
    }

    this.appendDataLayer();

    if (!this.env.production) {
      return;
    }

    this.appendGoogleTagScript(key);
    this.watchRouterNavigationEvents();

    this.isInjected = true;
  }

  private appendDataLayer() {
    const scriptElement = this.document.createElement('script');
    scriptElement.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
     `;
    this.document.documentElement.firstChild.appendChild(scriptElement);
  }

  private appendGoogleTagScript(key: string): void {
    const gtagScript = this.document.createElement('script');
    gtagScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${key}');`;
    this.document.head.appendChild(gtagScript);
  }

  private watchRouterNavigationEvents() {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      gtag('set', 'page_path', event.urlAfterRedirects);
      gtag('event', 'page_view');
    });
  }
}
