<table *ngIf="hasItemsToShow()">
  <tr>
    <th>{{ "BOATS" | translate }}</th>
    <th colspan="2">{{ hasSomeReservation() ? ("BOOKINGS" | translate) : "" }}</th>
  </tr>
  @for (occupancy of mappedOccupancy(); track occupancy.resourceId) {
    <tr>
      <td><dm-map-map-berth-details-boat-name [name]="occupancy.boat.name" [boatId]="occupancy.boat.id" /></td>

      @if (occupancy.booking || occupancy.temporaryAbsence) {
        <td>
          @if (occupancy.booking) {
            <dm-map-popup-row [label]="occupancy.booking.contractType | contractTypeTranslate">
              <a
                class="details-link text-nowrap"
                [routerLink]="['/', marinaCode, 'bookings', occupancy.booking.reservationId, 'details']"
                [state]="{ backOnClose: true }"
                returnUrlCurrentPath
                >{{ occupancy.booking.humanReadableId }}</a
              >
            </dm-map-popup-row>
          }
          <!--          @if (occupancy.temporaryAbsence) {-->
          <!--            <dm-map-popup-row-->
          <!--              [label]="'GAP_END' | translate"-->
          <!--              [txt]="occupancy.temporaryAbsence.expectedReturnDate | dateFormat"-->
          <!--            />-->
          <!--          } @else {-->
          <dm-map-popup-row [label]="'END_DATE' | translate" [txt]="occupancy.booking.toDate | dateFormat" />
          <!--          }-->
        </td>
      }

      <td [colSpan]="occupancy.booking || occupancy.temporaryAbsence ? 1 : 2">
        <div class="buttons">
          @if (!occupancy.booking && !occupancy.temporaryAbsence) {
            <button type="button" class="btn btn-primary btn-tiny" (click)="quickQuote(occupancy)">
              {{ "QUICK_QUOTE" | translate | uppercase }}
            </button>
          }
          @if (
            (occupancy.expectedDepartures && occupancy.boatIsInBerth) ||
            (!occupancy.booking && !occupancy.temporaryAbsence)
          ) {
            <button type="button" class="btn btn-secondary btn-tiny" (click)="departOrArrivalBoat(occupancy)">
              {{ (occupancy.expectedDepartures ? "REPORT_DEPARTURE" : "DEPART_BOAT") | translate | uppercase }}
            </button>
          }
          @if (occupancy.expectedArrivals && !occupancy.boatIsInBerth) {
            <button type="button" class="btn btn-secondary btn-tiny" (click)="departOrArrivalBoat(occupancy, true)">
              {{ "REPORT_ARRIVAL" | translate | uppercase }}
            </button>
          }
          @if ((occupancy.booking && occupancy.boatIsInBerth && !occupancy.expectedDepartures) || !occupancy.booking) {
            <button type="button" class="btn btn-secondary btn-tiny" (click)="moveBoat(occupancy)">
              {{ "MOVE_BOAT" | translate | uppercase }}
            </button>
          }
          @if (
            !occupancy.boatIsInBerth &&
            !occupancy.expectedDepartures &&
            !occupancy.expectedArrivals &&
            occupancy.booking
          ) {
            <button type="button" class="btn btn-secondary btn-tiny" (click)="transferBooking(occupancy.booking)">
              {{ "TRANSFER_BOOKING" | translate | uppercase }}
            </button>
          }
          <!--        @if (occupancy.temporaryAbsence) {-->
          <!--          <button type="button" class="btn btn-secondary btn-tiny" (click)="transferBooking(occupancy)">-->
          <!--            {{ "EDIT_GAP" | translate | uppercase }}-->
          <!--          </button>-->
          <!--        }-->
        </div>
      </td>
    </tr>
  }
</table>
