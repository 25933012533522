<ng-container>
  <div class="d-flex align-items-center">
    <div class="flex-grow-1">
      <dm-map-popup-row [label]="'PIER_BERTH' | translate" [txt]="name" [size]="size" />
    </div>
    <div class="statuses">
      @for (status of berthStatuses; track status) {
        <dm-ui-tag [color]="status | tagColorByBerthStat" size="normal" *ngIf="status">
          {{ "MAP_BERTH_STATUS." + status | translate }}
        </dm-ui-tag>
      }
    </div>
  </div>
</ng-container>
