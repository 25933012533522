import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResendToCrmComponent } from './components/resend-to-crm/resend-to-crm.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { UiModule } from '@dm-workspace/ui';
import { RouterModule } from '@angular/router';
import { OrdersListComponent } from './components/orders-list/orders-list.component';
import { LibsSharedModule } from '@dm-workspace/shared';
import {
  MmsBookingMediaAccessModalComponent,
  MmsCustomerContactFormModalComponent,
  NewBoatModalComponent,
} from './modals';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LibsFormsModule } from '@dm-workspace/forms';
import { MmsBookingProductsListComponent } from './components/mms-booking-products-list/mms-booking-products-list.component';
import { MmsCustomerFormComponent } from './forms/mms-customer-form/mms-customer-form.component';
import { MmsBookingsSourceNamePipe } from './pipes/mms-bookings-source-name.pipe';
import { MmsProductsSumPipe } from './pipes/mms-products-sum.pipe';
import { MmsPaymentsListComponent } from './components/mms-payments-list/mms-payments-list.component';
import { HasPermissionPipe } from './pipes/mms-has-permission.pipe';
import { BookingActionButtonComponent } from './components/booking-action-button/booking-action-button.component';
import { MmsCustomerPreviewComponent } from './forms/mms-customer-details-preview/mms-customer-preview.component';
import { MmsPaymentStatusTagColorPipe } from './pipes/mms-payment-status-tag-color.pipe';
import { MmsPaymentStatusTranslateKeyPipe } from './pipes/mms-payment-status-translate-key.pipe';
import { MmsContactChangeEmailModalComponent } from './modals/mms-contact-change-email-modal/mms-contact-change-email-modal.component';
import { MmsOrderPaymentStatusTranslateKeyPipe } from './pipes/mms-order-payment-status-translate-key.pipe';
import { MmsBoatDetailsPreviewComponent } from './components/mms-boat-details-preview/mms-boat-details-preview.component';
import { MmsBookingActionTranslateKeyPipe } from './pipes/mms-booking-action-translate-key.pipe';
import { MmsBookingCanMarkAsPaidPipe } from './pipes/mms-booking-can-mark-as-paid.pipe';
import { SelectedMarinaRouterLinkDirective } from './directives/selected-marina-router-link.directive';
import { ReplaceMarinaTokenWithSelectedMarinaCodePipe } from './pipes/mms-transform-router-link.pipe';
import { MmsBookingActionsButtonComponent } from './components/mms-booking-actions-button/mms-booking-actions-button.component';
import { MmsTableCellBookingCustomerComponent } from './table/mms-table-cell-booking-customer/mms-table-cell-booking-customer.component';
import { MmsTableCellFlagComponent } from './table/mms-table-cell-flag/mms-table-cell-flag.component';
import { MmsBookingEditablePipe } from './pipes/mms-booking-editable.pipe';
import { MmsTableCellBoatComponent } from './table/mms-table-cell-boat/mms-table-cell-boat.component';
import { MmsQuotationStatusTagColorPipe } from './pipes/mms-quotation-status-tag-color.pipe';
import { MmsFilterInvisibleBookingLabelsPipe } from './pipes/mms-filter-invisible-booking-labels.pipe';
import { MmsBoatMovementConfigProvider } from './tokens/mms-boat-movement.config';
import { MmsBoatMovementWarningConfirmModalComponent } from './modals/mms-boat-movement-warning-confirm-modal/mms-boat-movement-warning-confirm-modal.component';
import { MmsTableCellStartEndComponent } from './table/mms-table-cell-start-end/mms-table-cell-start-end.component';
import { MmsQuoteStatusTagColorPipe } from './pipes/mms-quote-status-color.pipe';
import { OfferQuoteStatusTranslatePipe } from './pipes/mms-quote-status-translate.pipe';
import { MmsQuoteChangeValidUntilModalComponent } from './modals/mms-quote-change-valid-until-modal/mms-quote-change-valid-until-modal.component';
import { MmsQuoteMoveToWaitingListModalComponent } from './modals/mms-quote-move-to-waiting-list-modal/mms-quote-move-to-waiting-list-modal.component';
import { MmsQuoteActionCancelModalComponent } from './modals/mms-quote-action-cancel-modal/mms-quote-action-cancel-modal.component';
import { MmsQuoteCancelReasonTranslateKeyPipe } from './pipes/mms-quote-cancel-reason-translate-key.pipe';
import { MmsQuoteActionDeclineModalComponent } from './modals/mms-quote-action-decline-modal/mms-quote-action-decline-modal.component';
import { MmsQuoteDeclineReasonTranslateKeyPipe } from './pipes/mms-offer-decline-reason-translate-key.pipe';
import { MmsQuoteActionCreateNewVersionModalComponent } from './modals/mms-quote-action-create-new-version/mms-quote-action-create-new-version-modal.component';
import { MmsMarinasSupportViewsConfigProvider } from './tokens/mms-marinas-support-views.config';
import { MmsSelectedMarinaSupportViewPipe } from './pipes/mms-selected-marina-support-view.pipe';
import { MmsQuoteActionMarkAsPaidModalComponent } from './modals/mms-quote-action-mark-as-paid-modal/mms-quote-action-mark-as-paid-modal.component';
import { MmsCardRecordComponent } from './components/mms-card-record/mms-card-record.component';
import { MmsCustomerIsCorporatePipe } from '@dm-workspace/shared';
import { MmsBoatAddMovementModalComponent } from './modals/mms-boat-add-movement-modal/mms-boat-add-movement-modal.component';
import { MmsBoatMovementFormComponent } from './components/mms-boat-movement-form/mms-boat-movement-form.component';
import { MmsBoatMovementSourceMapPipe } from './pipes/mms-boat-movement-source-map.pipe';
import { MmsBoatMovementOperationTranslateKeyPipe } from './pipes/mms-boat-movement-translate-key.pipe';
import { MapUtilsModule } from '@dm-workspace/map-utils';
import { MmsTransferBookingModalComponent } from './modals/mms-transfer-booking-modal/mms-transfer-booking-modal.component';
import { MmsTimelineItemComponent } from './components/mms-timeline-item/mms-timeline-item.component';

const declarations = [
  ResendToCrmComponent,
  MmsBookingActionsButtonComponent,
  BookingActionButtonComponent,
  OrdersListComponent,
  MmsBookingMediaAccessModalComponent,
  MmsCustomerContactFormModalComponent,
  MmsCustomerFormComponent,
  NewBoatModalComponent,
  MmsBookingsSourceNamePipe,
  MmsProductsSumPipe,
  MmsPaymentsListComponent,
  HasPermissionPipe,
  MmsCustomerPreviewComponent,
  MmsPaymentStatusTranslateKeyPipe,
  MmsContactChangeEmailModalComponent,
  MmsPaymentStatusTagColorPipe,
  MmsOrderPaymentStatusTranslateKeyPipe,
  MmsBoatDetailsPreviewComponent,
  ReplaceMarinaTokenWithSelectedMarinaCodePipe,
  MmsBookingActionTranslateKeyPipe,
  MmsBookingCanMarkAsPaidPipe,
  SelectedMarinaRouterLinkDirective,
  MmsTableCellBookingCustomerComponent,
  MmsTableCellFlagComponent,
  MmsBookingEditablePipe,
  MmsTableCellBoatComponent,
  MmsQuotationStatusTagColorPipe,
  MmsFilterInvisibleBookingLabelsPipe,
  MmsBoatMovementWarningConfirmModalComponent,
  MmsTableCellStartEndComponent,
  MmsQuoteStatusTagColorPipe,
  OfferQuoteStatusTranslatePipe,
  MmsQuoteChangeValidUntilModalComponent,
  MmsQuoteMoveToWaitingListModalComponent,
  MmsQuoteActionCancelModalComponent,
  MmsQuoteCancelReasonTranslateKeyPipe,
  MmsQuoteActionDeclineModalComponent,
  MmsQuoteDeclineReasonTranslateKeyPipe,
  MmsQuoteActionCreateNewVersionModalComponent,
  MmsSelectedMarinaSupportViewPipe,
  MmsQuoteActionMarkAsPaidModalComponent,
  MmsCardRecordComponent,
  MmsBoatMovementOperationTranslateKeyPipe,
  MmsBoatMovementFormComponent,
  MmsTransferBookingModalComponent,
  MmsBoatMovementSourceMapPipe,
  MmsBoatAddMovementModalComponent,
  MmsCustomerIsCorporatePipe,
  MmsTimelineItemComponent,
];

@NgModule({
  declarations: [...declarations, MmsBookingProductsListComponent],
  exports: [...declarations],
  imports: [
    CommonModule,
    RouterModule,
    MapUtilsModule,
    LibsSharedModule,
    TranslateModule,
    NgbDropdownModule,
    UiModule,
    RouterModule,
    FormsModule,
    LibsFormsModule,
    ReactiveFormsModule,
    NgbInputDatepicker,
  ],
  providers: [MmsBoatMovementConfigProvider, MmsMarinasSupportViewsConfigProvider],
})
export class MmsPanelSharedModule {}
