import { BoatMovementOperation } from '@dm-workspace/types';
import {
  MmsBoatMovementForm,
  MmsBoatMovementFormBuilder,
  MmsBoatMovementFormVersion,
} from './mms-boat-movement-form.builder';

export class MmsBoatMovementFormDecorator {
  #formBuilder: MmsBoatMovementFormBuilder;

  constructor(
    private form: MmsBoatMovementForm,
    private version: MmsBoatMovementFormVersion
  ) {
    this.#formBuilder = new MmsBoatMovementFormBuilder(this.form, version);
  }

  changeByOperation(operation: BoatMovementOperation, sourceId?: string) {
    this.#formBuilder.removeStartAt().removeAllowsBerthReuse().removeDestinationId().removeEndsAt().removeSourceId();
    if (!operation) {
      return;
    }

    const startsAtDefaultValue = new Date().toISOString();

    switch (operation) {
      case BoatMovementOperation.ARRIVAL: {
        const startAtValue = new Date(new Date().setHours(14, 0, 0, 0)).toISOString();
        this.#formBuilder.addStartAt(startAtValue).addDestinationId();
        break;
      }
      case BoatMovementOperation.DRY_DOCK_LIFT: {
        this.#formBuilder
          .addStartAt(startsAtDefaultValue)
          .addSourceId(sourceId)
          .addDestinationId()
          .addAllowsBerthReuse()
          .addStartAt()
          .addEndsAt();
        this.changeByAllowsBerthReuse(false);
        break;
      }
      case BoatMovementOperation.DEPARTURE: {
        const startAtValue = new Date(new Date().setHours(12, 0, 0, 0)).toISOString();
        this.#formBuilder.addStartAt(startAtValue).addSourceId(sourceId);
        break;
      }
      case BoatMovementOperation.DRY_DOCK_LAUNCH: {
        this.#formBuilder.addStartAt(startsAtDefaultValue).addSourceId().addDestinationId();
        break;
      }
      case BoatMovementOperation.CRUISING: {
        this.#formBuilder.addStartAt(startsAtDefaultValue).addSourceId(sourceId).addEndsAt().addAllowsBerthReuse();

        switch (this.version) {
          case MmsBoatMovementFormVersion.NEW: {
            // this.form.controls.startsAt?.disable();
            this.changeByAllowsBerthReuse(false);
            break;
          }
          case MmsBoatMovementFormVersion.OLD: {
            this.form.controls.endsAt?.disable();
            break;
          }
        }

        break;
      }
      case BoatMovementOperation.INTERNAL_MOVEMENT: {
        this.#formBuilder.addStartAt(startsAtDefaultValue).addSourceId(sourceId).addDestinationId();
        break;
      }
      case BoatMovementOperation.RETURN: {
        this.#formBuilder.addStartAt(startsAtDefaultValue).addDestinationId();
        break;
      }
    }
  }

  changeByAllowsBerthReuse(value: boolean) {
    if (this.version === MmsBoatMovementFormVersion.OLD) {
      return;
    }

    const { endsAt } = this.form.controls;

    if (value) {
      endsAt?.setValidators(this.#formBuilder.getEndsAtValidators());
    } else {
      endsAt?.clearValidators();
    }
    endsAt?.updateValueAndValidity();
  }
}
