import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { showErrorAnimation } from '../../animations/show-error.animation';
import { getValidatorsErrorKey } from '../../validators/errors-messages';
import { DmarinFailedValidationSpecificError } from '@dm-workspace/types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dm-form-error-message',
  templateUrl: './form-error-message.component.html',
  animations: [showErrorAnimation],
})
export class FormErrorMessageComponent implements OnChanges, AfterViewInit, OnDestroy {
  /**
   * @deprecated
   * Use ApiValidatorService.parseServerSideErrors
   */
  @Input() apiError?: undefined | DmarinFailedValidationSpecificError[];
  @Input() required?: string;
  @Input() pattern?: string;
  @Input() error?: string;
  @Input() control?: AbstractControl | null = null;
  @Input() controlName?: string;
  @Output() errorAppear = new EventEmitter<void>();
  private controlSubscribe?: Subscription;

  constructor(
    @Optional() private controlContainer: ControlContainer,
    private translateService: TranslateService
  ) {}

  get showError(): boolean {
    return this.control ? this.control.dirty : false;
  }

  get errorMessage(): string[] | null {
    if (this.control && this.showError) {
      this.errorAppear.emit();
      for (const key in this.control.errors) {
        const errorMessage = this.control.errors[key];
        if (!errorMessage) {
          return;
        }

        if (!['apiError', 'server'].includes(key)) {
          if (key === 'required' && this.required) {
            return [this.translateService.instant(this.required)];
          }
          if (key === 'pattern' && this.pattern) {
            return [this.pattern];
          }

          if (['maxlength', 'minlength'].includes(key) && typeof this.control.value === 'object') {
            return [this.translateService.instant(getValidatorsErrorKey(`items${key}`), errorMessage)];
          }
          return [this.translateService.instant(getValidatorsErrorKey(key), errorMessage)];
        } else if (key === 'server') {
          return [errorMessage];
        }
      }
    }
    if (this.apiError && this.apiError.length) {
      return this.apiError.map((value) => value && (value.code ? `API_ERRORS.${value.code}` : value.message));
    } else if (this.error) {
      return [`ERRORS.${this.error}`];
    }

    return null;
  }

  private addSubToControl(): void {
    if (this.control) {
      this.controlSubscribe = this.control.valueChanges.subscribe((data) => {
        if (this.apiError) {
          delete this.apiError;
        }
      });
    }
  }

  private connectControl(): void {
    if (this.controlName && this.controlContainer && this.controlContainer.control instanceof UntypedFormGroup) {
      this.control = (this.controlContainer.control as UntypedFormGroup).get(this.controlName);
    }
  }

  public ngAfterViewInit(): void {
    this.connectControl();
    this.addSubToControl();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.apiError && changes.apiError.currentValue && this.control) {
      this.control.setErrors({
        apiError: true,
      });
      this.control.markAsDirty();
    }
  }

  public ngOnDestroy(): void {
    this.controlSubscribe?.unsubscribe();
  }
}
