import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BerthStatusInMarina } from '@dm-workspace/types';

@Component({
  selector: 'dm-map-berth-status',
  templateUrl: './map-berth-status.component.html',
  styleUrls: ['./map-berth-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBerthStatusComponent {
  @Input() berthStatuses?: BerthStatusInMarina[] = [];
  @Input() name?: string;
  @Input() size?: 'm' | 'l' = 'm';
}
