import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Ability, IResourceBookingQuotation, OrderPaymentStatus, QuotationStatus } from '@dm-workspace/types';
import { ResourceBookingApiService } from '@dm-workspace/data-access';
import { BoatMovementService } from '../../services/boat-movement.service';
import { NotificationService } from '@dm-workspace/notification';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isStatusForTransfer } from '@dm-workspace/utils';
import { MmsBoatReportArrivalModalComponent } from '../../modals/boat-arrival-modal/mms-boat-report-arrival-modal.component';
import { BoatCruiseModalComponent } from '../../modals/boat-cruise-modal/boat-cruise-modal.component';
import { BoatReturnModalComponent } from '../../modals/boat-return-modal/boat-return-modal.component';
import { BoatVerifyCruiseModalComponent } from '../../modals/boat-verify-cruise-modal/boat-verify-cruise-modal.component';
import { ResourceBookingCancellationModalComponent } from '../../modals/resource-booking-cancellation-modal/resource-booking-cancellation-modal.component';
import { MmsBoatDepartureModalComponent } from '../../modals/mms-boat-departure-modal/mms-boat-departure-modal.component';
import { tap } from 'rxjs/operators';
import { filter } from 'rxjs';
import { MmsOrderActionMarkAsPaidModalComponent } from '../../../../../mms-orders/src/lib/modals/mms-order-action-mark-as-paid/mms-order-action-mark-as-paid-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';

@Component({
  selector: 'dm-mms-shared-booking-actions-button',
  templateUrl: './mms-booking-actions-button.component.html',
  styleUrls: ['./mms-booking-actions-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsBookingActionsButtonComponent {
  readonly #notificationService = inject(NotificationService);
  /** TODO:
   * delete this.#resourceBookingService.triggerViewRefresh(); and move this to (actionSuccess)
   */
  readonly #resourceBookingService = inject(ResourceBookingApiService);
  readonly #boatMovement = inject(BoatMovementService);
  readonly #modal = inject(NgbModal);
  protected readonly QuoteStatus = QuotationStatus;
  protected readonly Ability = Ability;
  protected readonly OrderPaymentStatus = OrderPaymentStatus;
  protected readonly QuotationStatus = QuotationStatus;
  public isStatusForTransfer = isStatusForTransfer;

  @Input() booking: IResourceBookingQuotation;
  @Output() actionSuccess = new EventEmitter<void>();

  protected changeStatus(booking: IResourceBookingQuotation, status: QuotationStatus, successMessage: string): void {
    const { marina: marinaCode, id } = booking;
    this.#resourceBookingService.changeStatus(id, status, marinaCode).subscribe({
      next: () => {
        this.#resourceBookingService.triggerViewRefresh();
        this.#notificationService.openSuccess(successMessage);
        this.actionSuccess.emit();
      },
      error: (res: HttpErrorResponse) => {
        this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
      },
    });
  }

  protected clickButtonMarkOrderAsPaid(booking: IResourceBookingQuotation): void {
    const modalComponent = this.#modal.open(MmsOrderActionMarkAsPaidModalComponent, {
      size: 'md',
    }).componentInstance as MmsOrderActionMarkAsPaidModalComponent;
    modalComponent.orderId = booking.primaryOrder.id;
    modalComponent.confirmed
      .pipe(
        filter(Boolean),
        tap(() => {
          this.#resourceBookingService.triggerViewRefresh();
          this.actionSuccess.emit();
        })
      )
      .subscribe();
  }

  protected moveBoat(booking: IResourceBookingQuotation) {
    const { boat, resource, fromDate, toDate, id: reservationId } = booking;
    if (boat && boat.id) {
      const action = isStatusForTransfer(boat.statusInMarina)
        ? this.#boatMovement.openTransferBookingModal({
            boatId: boat.id,
            resource: resource,
            toDate: toDate,
            fromDate: fromDate,
            reservationId,
          })
        : this.#boatMovement.openBoatMovementModal(
            {
              boat: boat,
              resourceId: resource.id,
            },
            booking.marina
          );

      action.subscribe((value) => {
        if (value) {
          this.#resourceBookingService.triggerViewRefresh();
          this.actionSuccess.emit();
        }
      });
    }
  }

  public openModal(
    action: 'arrival' | 'cruise' | 'return' | 'cancel' | 'verifyCruise',
    booking: IResourceBookingQuotation
  ): void {
    const modals = {
      arrival: MmsBoatReportArrivalModalComponent,
      cruise: BoatCruiseModalComponent,
      return: BoatReturnModalComponent,
      cancel: ResourceBookingCancellationModalComponent,
      verifyCruise: BoatVerifyCruiseModalComponent,
    };

    const modal = this.#modal.open(modals[action], {
      modalDialogClass: `booking-boat-confirmation-modal-container modal-type-${action}`,
    });
    const modalComponent = modal.componentInstance;
    modalComponent.booking = booking;

    modal.closed.subscribe((confirmed: boolean) => {
      if (!confirmed) {
        return;
      }
      this.#resourceBookingService.triggerViewRefresh();
      this.actionSuccess.emit();
    });
  }

  public openMarkAsPaidModal(booking: IResourceBookingQuotation) {
    const modal = this.#modal.open(MmsOrderActionMarkAsPaidModalComponent, {
      size: 'md',
    });
    const modalComponent = modal.componentInstance as MmsOrderActionMarkAsPaidModalComponent;
    modalComponent.orderId = booking.primaryOrder.id;

    modal.closed.subscribe((confirmed: boolean) => {
      if (!confirmed) {
        return;
      }
      this.#resourceBookingService.triggerViewRefresh();
      this.actionSuccess.emit();
    });
  }

  public openDepartureModal(booking: IResourceBookingQuotation) {
    const modal = this.#modal.open(MmsBoatDepartureModalComponent, {
      size: 'md',
    });
    const modalComponent = modal.componentInstance as MmsBoatDepartureModalComponent;
    modalComponent.boatId = booking.boat.id;
    modalComponent.resource = booking.resource;

    modalComponent.confirmed.subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }
      this.#resourceBookingService.triggerViewRefresh();
      this.actionSuccess.emit();
    });
  }
}
