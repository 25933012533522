import { ControlValueAccessor } from '@angular/forms';
import { Directive, HostBinding, input, signal, ContentChild, inject, ChangeDetectorRef } from '@angular/core';
import { catchError, concat, debounceTime, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CustomSearchNoResultDirective } from './custom-search-no-result.directive';

@Directive()
export abstract class CustomSearchComponent<T> implements ControlValueAccessor {
  #cd = inject(ChangeDetectorRef);
  @HostBinding('class')
  componentClassName = 'custom-search';
  @ContentChild(CustomSearchNoResultDirective)
  noResultDirective: CustomSearchNoResultDirective;
  abstract fetchItems(searchTerm: string): Observable<T[]>;
  abstract fetchItem(id: string): Observable<T>;
  public bindValue = input<keyof T>();
  // TODO: placeholder should be a string rather than a translate key
  public placeholder = input<string>('TYPE_TO_SEARCH_THE_LIST');
  public clearable = input(false);
  public disabled = signal(false);
  public loading = signal(false);
  public input$ = new Subject<string>();
  public items$: Observable<T[]> = concat(
    of([]),
    this.input$.pipe(
      tap(() => this.loading.set(true)),
      debounceTime(300),
      switchMap((term) =>
        this.getItemBySearchTerm(term).pipe(
          tap(() => this.loading.set(false)),
          catchError(() => [])
        )
      )
    )
  );
  value: T;
  onChange: (selected: T | null | string) => void = () => undefined;

  public registerOnChange(fn: never): void {
    this.onChange = fn;
  }

  getItemBySearchTerm(search: string): Observable<T[]> {
    if (!search) {
      return of([]);
    }
    return this.fetchItems(search);
  }
  protected onTouched: () => void = () => undefined;

  public registerOnTouched(fn: never): void {
    this.onTouched = fn;
  }
  public onSelectChange($event: T): void {
    this.value = $event;
    this.input$.next('');
    this.onTouched();
    this.emitValue();
  }

  emitValue() {
    if (this.value && this.bindValue()) {
      this.onChange(this.value[this.bindValue()] as string);
    } else {
      this.onChange(this.value);
    }
  }

  writeValue(value: T | string): void {
    if (typeof value === 'string') {
      this.getItem(value);
    } else {
      this.value = value;
    }
    this.#cd.detectChanges();
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled.set(isDisabled);
  }
  getItem(id: string) {
    if (this.loading()) {
      return;
    }
    this.loading.set(true);
    this.fetchItem(id)
      .pipe(finalize(() => this.loading.set(false)))
      .subscribe((user) => {
        this.value = user;
        if (!this.bindValue()) {
          this.emitValue();
        }
      });
  }
}
