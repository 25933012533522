import { IEnvironment } from './interfaces/environment.interface';

export const environmentDefault: IEnvironment = {
  production: false,
  stage: false,
  // apiUrl: 'http://172.20.1.147:3000',
  apiUrl: 'https://dmarin-api-dev.futuremind.dev',
  // apiUrl: 'https://dmarin-api-stage.futuremind.dev',
  // apiUrl: 'https://api.d-marinmarinas.com',
  cmsApiUrl: 'https://d-marin-portal-backend.dev.futuremind.dev/api/',
  crmUrl: 'https://dmaringroup-uat.crm4.dynamics.com/main.aspx',
  staticUrl: 'https://dmarin-mms-dev.futuremind.dev/static/',
  sentry: 'https://cd3202bf1284f361fae23a530de28d3e@ingest.sentry.futuremind.network/24',
  tokenName: 'DM_T',
  mapApiKey: 'AIzaSyBSmH8lcImUyNvdI3QmYUWFefK0LVHxIEw',
  mapId: '3121dcaa9b8d04e0',
  openMobileAppUrl: 'https://dmarin.page.link/app',
  googleTagManager: {
    booking: 'GTM-5CXHR5P5',
  },
  embeddedMap: 'https://d-marin-portal-frontend.dev.futuremind.dev/embeded-map',
  recaptchaKey: '6LdyiqUfAAAAAEZ7lfJaqC2yMIGSNY1fP70VjW68',
  googlePay: {
    scriptUrL: 'https://pay.google.com/gp/p/js/pay.js',
  },
  appStores: {
    Google: {
      url: 'https://play.google.com/store/apps/details?id=',
      id: 'com.dmarin',
    },
    Apple: {
      url: 'https://apps.apple.com/us/app/d-marin-premium-marinas/id',
      id: '6446983110',
    },
  },
  adyen: {
    environment: 'test',
  },
  resetPasswordUrl: 'https://dmarin-customer-dev.futuremind.dev/auth/password-lost',
  enableServiceWorker: true,
};
