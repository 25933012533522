import { ChangeDetectionStrategy, Component, forwardRef, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MmsCustomerApiService } from '@dm-workspace/data-access';
import { Customer, MARINA_URL_TOKEN } from '@dm-workspace/types';
import { map, Observable } from 'rxjs';
import { CustomSearchComponent } from '../customeSeach.class';
import { CustomerUtils } from '../../../../../../utils/src/lib/customer.utils';

@Component({
  selector: 'dm-form-search-customer',
  templateUrl: './search-customer.component.html',
  styleUrl: './search-customer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchCustomerComponent),
      multi: true,
    },
  ],
})
export class SearchCustomerComponent extends CustomSearchComponent<Customer> {
  fetchItem(id: string): Observable<Customer> {
    return this.#customersApiService.fetchById(id);
  }
  #customersApiService = inject(MmsCustomerApiService);
  fetchItems(search: string): Observable<Array<Customer & { disabled: boolean }>> {
    return this.#customersApiService.searchCustomers({ search, eligibleInMarina: MARINA_URL_TOKEN }).pipe(
      map((res) =>
        res.values.sort((a, b) => {
          const aHasAccount = CustomerUtils.hasOnlineAccount(a);
          const bHasAccount = CustomerUtils.hasOnlineAccount(b);
          return aHasAccount === bHasAccount ? 0 : aHasAccount ? -1 : 1;
        })
      ),
      map((res) => res.map((customer) => ({ ...customer, disabled: !customer.isActive })))
    );
  }
}
