import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ResourceBookingApiService } from '@dm-workspace/data-access';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@dm-workspace/notification';
import { catchError, defer, finalize, shareReplay, startWith, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';

@Component({
  selector: 'dm-mms-shared-booking-media-access-modal',
  templateUrl: './mms-booking-media-access-modal.component.html',
  styleUrls: ['./mms-booking-media-access-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsBookingMediaAccessModalComponent {
  public sendingRequest = false;
  public readonly bookingAvailableProducts$ = defer(() => {
    return this.resourceBookingApiService.getBookingAvailableMediaAccess(this.bookingId);
  }).pipe(
    catchError((res: HttpErrorResponse) => {
      this.notification.openError(ApiValidator.getApiNotificationError(res));
      this.activeModal.dismiss();
      return throwError(() => res);
    }),
    tap((bookingProducts) => {
      if (!bookingProducts.length) {
        this.notification.openError('MISSING_AVAILABLE_MEDIA_ACCESS');
        this.activeModal.dismiss();
      }
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  public readonly totalPaymentForProducts$ = this.bookingAvailableProducts$.pipe(
    map((products) => products.reduce((acc, curr) => acc + curr.pricing.sumPriceGross, 0))
  );
  public readonly currency$ = this.bookingAvailableProducts$.pipe(map((products) => products[0]?.pricing.currency));
  public readonly fetchingAvailableMedia$ = this.bookingAvailableProducts$.pipe(
    map(() => false),
    startWith(true)
  );
  @Input() public bookingId: string;

  constructor(
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
    private resourceBookingApiService: ResourceBookingApiService,
    private cd: ChangeDetectorRef
  ) {}

  public onSubmit() {
    this.sendingRequest = true;
    this.resourceBookingApiService
      .setBookingMediaAccess(this.bookingId)
      .pipe(
        finalize(() => {
          this.sendingRequest = false;
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: () => {
          this.notification.openSuccess();
          this.activeModal.close(true);
        },
        error: (res: HttpErrorResponse) => {
          this.notification.openError(ApiValidator.getApiNotificationError(res));
        },
      });
  }

  public dismissModal() {
    this.activeModal.dismiss();
  }
}
