<dm-ui-modal>
  <h2 header>{{ "ADD_BOAT_MOVEMENT" | translate }}</h2>
  <div body>
    <dm-ui-info class="color-danger">
      <i icon class="i-exclamation-mark-circle"></i>
      <div body>
        @for (warningKey of warningsKeys; track warningKey) {
          <p>{{ "BOAT_MOVEMENT_WARNING_." + warningKey | translate }}</p>
        }
      </div>
    </dm-ui-info>

    <dm-ui-horizontal-line class="my-4" />

    <p>{{ "ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_MOVEMENT" | translate }}</p>
  </div>

  <ng-container footer>
    <button type="button" class="btn btn-secondary-negative" (click)="onCancel()">
      {{ "CANCEL" | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()">
      {{ "ADD_MOVEMENT" | translate }}
    </button>
  </ng-container>
</dm-ui-modal>
