<table *ngIf="hasItemsToShow()">
  <tr>
    <th>{{ "BOATS" | translate }}</th>
    <th colspan="2">{{ "BOOKINGS" | translate }}</th>
  </tr>
  @for (reservation of berth().data.bookings; track reservation.reservationId) {
    <tr>
      <td><dm-map-map-berth-details-boat-name [name]="reservation.boat.name" [boatId]="reservation.boat.id" /></td>
      <td>
        <dm-map-popup-row [label]="reservation.contractType | contractTypeTranslate">
          <a
            class="details-link text-nowrap"
            [routerLink]="['/', marinaCode, 'bookings', reservation.reservationId, 'details']"
            [state]="{ backOnClose: true }"
            returnUrlCurrentPath
            >{{ reservation.humanReadableId }}</a
          >
        </dm-map-popup-row>
        <!--        @if (temporaryAbsence(reservation.boatId); as temporaryAbsence) {-->
        <!--          <dm-map-popup-row [label]="'GAP_END' | translate" [txt]="temporaryAbsence.expectedReturnDate | dateFormat" />-->
        <!--        } @else {-->
        <dm-map-popup-row [label]="'END_DATE' | translate" [txt]="reservation.toDate | dateFormat" />
        <!--        }-->
      </td>
      <td>
        <div class="buttons">
          @if (boatIsInOccupancy(reservation.boatId)) {
            <button type="button" class="btn btn-secondary btn-tiny" (click)="moveBoat(reservation)">
              {{ "MOVE_BOAT" | translate | uppercase }}
            </button>
          } @else {
            <button type="button" class="btn btn-secondary btn-tiny" (click)="transferBooking(reservation)">
              {{ "TRANSFER_BOOKING" | translate | uppercase }}
            </button>
          }
          <!--        @if (temporaryAbsence(reservation.boatId); as temporaryAbsence) {-->
          <!--          <button type="button" class="btn btn-secondary btn-tiny" (click)="transferBooking(reservation)">-->
          <!--            {{ "EDIT_GAP" | translate | uppercase }}-->
          <!--          </button>-->
          <!--        }-->
        </div>
      </td>
    </tr>
  }
</table>
