import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BerthMapResponseDto, BerthStatusInMarina, IBerthDto, Marina, MultihullPolygon } from '@dm-workspace/types';
import { debounceTime, finalize, forkJoin, fromEvent, map, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { MapViewTypes } from '@dm-workspace/utils';
import { BerthsApiService } from '@dm-workspace/data-access';
import { enterLeaveFromLeftAnimation } from '@dm-workspace/shared';
import { ActivatedRoute, Router } from '@angular/router';
import { MapPolygon } from '@angular/google-maps';
import { MAP_BOAT_ID_BOAT_TYPE } from '../../const/boat-temp';
import { MapBerthClass } from '../../berths.class';
import { AlertMapResponseDtoWithBerth } from '../map-alerts-drawer/map-alerts-drawer.component';
import {
  getBerthColorByStatus,
  MapComponentBase,
  MapService,
  MapTransformationsService,
} from '@dm-workspace/map-utils';
import { BerthsMapApiService } from '@dm-workspace/map-utils';

const SELECTED_QUERY_PARAM_NAME = 'selectedBerth';
@Component({
  selector: 'dm-map-mmi',
  templateUrl: './map-mmi.component.html',
  styleUrls: ['./map-mmi.component.scss'],
  providers: [
    {
      provide: MAP_BOAT_ID_BOAT_TYPE,
      useFactory: () => new Map(),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFromLeftAnimation],
})
export class MapMmiComponent
  extends MapComponentBase<MapBerthClass>
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() public viewType!: MapViewTypes;
  @Input() marina!: Marina;

  @Output() alertsCount: EventEmitter<number> = new EventEmitter<number>();
  protected showStatus: BerthStatusInMarina[] = [];
  private destroy$ = new Subject();

  public multihullsPolygon: MultihullPolygon[] = [];
  public pending = true;
  public alerts: AlertMapResponseDtoWithBerth[];

  constructor(
    mapService: MapService,
    el: ElementRef<HTMLElement>,
    private berthsApi: BerthsMapApiService,
    cd: ChangeDetectorRef,
    private mapTransformation: MapTransformationsService,
    private berthsMmsApi: BerthsApiService,
    private aRoute: ActivatedRoute,
    protected router: Router
  ) {
    super(mapService, cd, el, router);
  }

  public ngOnInit(): void {
    fromEvent(window, 'resize')
      .pipe(debounceTime(250), takeUntil(this.destroy$))
      .subscribe(() => {
        this.cd.markForCheck();
      });

    this.aRoute.fragment.pipe(takeUntil(this.destroy$)).subscribe((fragment) => {
      this.selectedAlertId = fragment;
      this.selectActiveAlert();
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { marina, viewType } = changes;

    if (marina && this.marina) {
      this.closeInfoWindow();
      this.selectedBerth = undefined;
      this.hoveredBerth = undefined;
      this.selectedMarina = this.marina;
      this.updateMarina();
      this.fetchBerthStatuses();
    }
    if (viewType) {
      this.onOccupationStateChange();
      if (viewType.previousValue === MapViewTypes.alerts) {
        this.setMarinaZoomCenter();
      } else if (this.selectedBerth) {
        this.updateQueryParams(this.selectedBerth.berth.data.berthName);
      }
    }
  }

  public ngAfterViewInit(): void {
    this.cd.markForCheck();
  }

  protected updateMarina() {
    if (!this.selectedMarina) {
      this.piers = [];
      return;
    }
    if (this.selectedMarina.layout?.center) {
      const [lng, lat] = this.selectedMarina.layout.center;
      this.center = { lng, lat };
    }
    this.piers = this.selectedMarina.layout.coordinates;
    this.cd.markForCheck();
    // this.piers = this.getLayotFromJson();
  }

  private updatePending(pending: boolean) {
    this.pending = pending;
    this.cd.markForCheck();
  }

  public fetchBerthStatuses(centerMarina = true): void {
    if (this.selectedMarina) {
      this.updatePending(true);
      forkJoin([this.fetchMmsBerths(), this.berthsMmsApi.fetchAll()])
        .pipe(
          map(([statuses, dimensions]) => this.mergeBerthsStatusesWithDimensions(statuses, dimensions)),
          tap((value) => this.updateAlerts(value)),
          tap((value) => this.checkMultihullsPolygons(value)),
          finalize(() => this.updatePending(false))
        )
        .subscribe((value) => {
          this.berths = value;

          if (this.selectedBerth) {
            const newSelectedBerth = value.find((v) => v.data.berthId === this.selectedBerth.berth.data.berthId);
            if (newSelectedBerth) {
              this.selectedBerth.berth = newSelectedBerth;
            }
          } else if (this.aRoute.snapshot.queryParamMap.get(SELECTED_QUERY_PARAM_NAME)) {
            this.selectBerthFromQuery();
          }
          if (centerMarina || this.viewType === MapViewTypes.alerts) {
            this.setMarinaZoomCenter();
          }
        });
    } else {
      this.berths = [];
    }
  }

  private fetchMmsBerths(): Observable<BerthMapResponseDto[]> {
    return this.berthsApi.fetchMmsBerths();
  }

  private updateAlerts(berths: MapBerthClass[]) {
    const alerts: AlertMapResponseDtoWithBerth[] = berths.reduce((alerts, berth) => {
      return [...alerts, ...berth.data.alerts.map((value) => ({ ...value, berth }))];
    }, []);

    this.alerts = alerts;
    this.alerts.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
    this.isLoaded$.pipe(take(1)).subscribe(() => {
      this.selectActiveAlert();
    });

    this.alertsCount.emit(alerts.length);
  }

  private setMarinaZoomCenter() {
    if (!this.berths) {
      return;
    }
    const latLngList = this.berths
      .filter((value) => !!value.data.polygon)
      .reduce((p, c) => [...p, ...c.data.polygon], []);

    if (latLngList.length > 0) {
      this.setMapBoundsAndCenter(latLngList);
    } else if (this.map) {
      this.map.googleMap.setZoom(17);
    }
  }

  protected setMapBoundsAndCenter(latLngArray: [number, number][], padding: number | google.maps.Padding = 0) {
    const padding_ = this.viewType === MapViewTypes.alerts ? { left: this.DRAWER_SPACING } : padding;
    super.setMapBoundsAndCenter(latLngArray, padding_);
    this.cd.markForCheck();
  }

  protected onMapLoad() {
    setTimeout(() => {
      this.setMarinaZoomCenter();
    }, 10);
  }

  public override onBerthPolygonHover(polygon: MapPolygon, berth: MapBerthClass) {
    if (this.hoverInfoWindow) {
      this.closeHoverInfoWindow();
    }

    if (!this.selectedBerth || this.selectedBerth.berth.data?.berthId !== berth.data.berthId) {
      this.handleBerthMouseEvent(polygon, berth, true);
    }
  }
  public onMapClick() {
    this.selectedBerth = undefined;
    this.hoveredBerth = undefined;
    this.clearSelectedAlert();
    this.updateQueryParams(undefined);
  }
  protected handleBerthMouseEvent(berthM: MapPolygon, berth: MapBerthClass, hover?: boolean) {
    super.handleBerthMouseEvent(berthM, berth, hover);
    if (!hover && this.selectedBerth) {
      this.updateQueryParams(this.selectedBerth.berth.data.berthName);
    }
  }
  updateQueryParams(selectedBerth: string | undefined) {
    this.router.navigate([], {
      relativeTo: this.aRoute,
      queryParams: { [SELECTED_QUERY_PARAM_NAME]: selectedBerth },
      queryParamsHandling: 'merge',
    });
  }
  public onMouseOut() {
    this.closeHoverInfoWindow();
  }

  private onOccupationStateChange() {
    if (this.viewType === MapViewTypes.alerts) {
      this.setMarinaZoomCenter();
      this.updateBerthStatuses();
    } else {
      this.updateBerthStatuses();
      this.checkMultihullsPolygons(this.berths);
    }
  }

  private updateBerthStatuses() {
    // this.berths = addBerthMapStatusToBerths(this.berths as BerthMapResponseDto[], this.viewType);
    this.cd.markForCheck();
  }

  public withUtilization(): boolean {
    return this.viewType === MapViewTypes.occupancy;
  }

  public get shouldShowAlerts(): boolean {
    return this.viewType === MapViewTypes.alerts;
  }

  private mergeBerthsStatusesWithDimensions(statuses: BerthMapResponseDto[], dimensions: IBerthDto[]): MapBerthClass[] {
    return statuses.map((status) => {
      const {
        length,
        width,
        depth,
        maxAllowedBoatWidth,
        maxAllowedBoatLength,
        minAllowedBoatLength,
        minAllowedBoatWidth,
      } = dimensions.find((dimension) => dimension.id === status.berthId) || {};
      return new MapBerthClass(status, {
        length,
        width,
        depth,
        maxAllowedBoatWidth,
        maxAllowedBoatLength,
        minAllowedBoatLength,
        minAllowedBoatWidth,
      });
    });
  }

  private checkMultihullsPolygons(berths: MapBerthClass[]) {
    const multihoolPolygons: MultihullPolygon[] = [];
    const reservations: Record<string, MapBerthClass[]> = {};
    const occupations: Record<string, MapBerthClass[]> = {};
    berths.forEach((value) => {
      if (value.data.bookings && value.visibleStatus(this.viewType, this.showStatus)) {
        value.data.bookings.forEach((reservation) => {
          reservations[reservation.humanReadableId] = reservations[reservation.humanReadableId] || [];
          reservations[reservation.humanReadableId].push(value);
        });
      }
    });

    [...Object.values(reservations), ...Object.values(occupations)]
      .filter((reservations) => reservations.length > 1)
      .forEach((berthAv) => {
        const poly = this.mapTransformation.getBerthsPolygon(berthAv.map((value) => value.data));
        const status = berthAv[0].visibleStatus(this.viewType, this.showStatus);
        multihoolPolygons.push({
          poly,
          color: getBerthColorByStatus(status),
        });
      });
    this.multihullsPolygon = multihoolPolygons;
  }

  public openBerthAlertInfoWindow(alert: AlertMapResponseDtoWithBerth): void {
    this.selectedBerth = undefined;
    this.closeHoverInfoWindow(0);
    this.selectBerth(alert.berth);
  }
  selectBerth(berth: MapBerthClass) {
    const latLngList = berth.data.polygon.map((value) => {
      const [lng, lat] = value;
      return new google.maps.LatLng(lat, lng);
    });
    const latLngBounds = new google.maps.LatLngBounds();
    latLngList.forEach((latLng) => {
      latLngBounds.extend(latLng);
    });

    this.selectedBerth = {
      lat: latLngBounds.getCenter(),
      berth,
    };

    this.map.googleMap.panToBounds(latLngBounds, { left: this.DRAWER_SPACING });
    this.openInfoWindow();
    this.cd.markForCheck();
  }

  private selectActiveAlert() {
    const selectedBerthByAlert = this.alerts?.find((alert) => alert.alertId === this.selectedAlertId);

    if (selectedBerthByAlert) {
      setTimeout(() => {
        this.openBerthAlertInfoWindow(selectedBerthByAlert);
      }, 10);
    }
  }

  onFiltersChange($event: BerthStatusInMarina[]) {
    this.showStatus = $event;
    this.checkMultihullsPolygons(this.berths);
    this.cd.detectChanges();
  }

  private selectBerthFromQuery() {
    const berthNameOrId = this.aRoute.snapshot.queryParamMap.get(SELECTED_QUERY_PARAM_NAME);
    const berth = this.berths.find(
      (value) => value.data.berthName === berthNameOrId || value.data.berthId === berthNameOrId
    );
    if (berth) {
      this.selectBerth(berth);
    }
  }
}
