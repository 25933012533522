import { inject, Injectable } from '@angular/core';
import {
  CancelDeclineQuoteBody,
  GetQuotesParams,
  MARINA_URL_TOKEN,
  MmsOfferSendTarget,
  PaginatedNormal,
  Quote,
  MmsQuoteChangeValidUntilParams,
  QuoteDetails,
  MmsQuoteMoveToWaitingListParams,
  EntityPaymentMethods,
  QuoteAvailablePaymentMethodsDto,
  CalculateQuotePricesDto,
  QuotePricingCalculationDto,
  CreateQuoteBody,
  QuoteUpsertDto,
  QuoteRevisionDto,
  MmsQuoteActivity,
  MmsQuoteNoteBody,
} from '@dm-workspace/types';
import { ApiCrudService, ApiHttpClient, ApiService } from '../api/api-http-client';
import { Observable } from 'rxjs';
import { FileUtil, FileUtilResponse } from '@dm-workspace/utils';
import { HttpClient } from '@angular/common/http';

export type MmsOfferSendParams = {
  target: MmsOfferSendTarget;
};

export type MmsOfferRemindCustomerParams = {
  target: MmsOfferSendTarget;
};

@Injectable({
  providedIn: 'root',
})
export class MmsQuotesApiService extends ApiService implements ApiCrudService {
  protected readonly apiHttpClient = inject(ApiHttpClient);
  protected readonly httpClient = inject(HttpClient);
  protected readonly resourceUrl = this.apiHttpClient.createResourceUrl(`/mms/marinas/${MARINA_URL_TOKEN}/quote`);

  getById(offerId: string) {
    return this.apiHttpClient.get<QuoteDetails>(`${this.resourceUrl}/${offerId}`);
  }

  get(params?: GetQuotesParams) {
    return this.apiHttpClient.get<PaginatedNormal<Quote>>(this.resourceUrl, {
      params,
    });
  }

  create(body: CreateQuoteBody) {
    return this.apiHttpClient.post<Quote>(this.resourceUrl, body);
  }

  edit(offerId: string, body: QuoteUpsertDto): Observable<{ id: string }> {
    return this.apiHttpClient.patch<{ id: string }>(`${this.resourceUrl}/${offerId}`, body);
  }

  cancel(offerId: string, body: CancelDeclineQuoteBody) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/actions/${offerId}/cancel`, body);
  }

  decline(offerId: string, body: CancelDeclineQuoteBody) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/actions/${offerId}/decline`, body);
  }

  markAsPaid(offerId: string) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/actions/${offerId}/mark-as-paid`, null);
  }

  approveDiscount(offerId: string) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/actions/${offerId}/approve-discount`, null);
  }

  remindCustomer(offerId: string, params: MmsOfferRemindCustomerParams) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/actions/${offerId}/remind-customer`, null, { params });
  }

  send(offerId: string, params: MmsOfferSendParams) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/actions/${offerId}/send`, null, { params });
  }

  transitionToOrder(offerId: string) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/actions/${offerId}/transition-to-order`, null);
  }

  sendPaymentReminder(offerId: string) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/actions/${offerId}/send-payment-reminder`, null);
  }

  changeValidUntil(quoteId: string, params: MmsQuoteChangeValidUntilParams) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/actions/${quoteId}/change-validity`, params);
  }

  moveToWaitingList(quoteId: string, params: MmsQuoteMoveToWaitingListParams) {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/actions/${quoteId}/put-on-waiting-list`, params);
  }

  getPaymentMethods(params: QuoteAvailablePaymentMethodsDto): Observable<EntityPaymentMethods> {
    return this.apiHttpClient.post<EntityPaymentMethods>(`${this.resourceUrl}/payment/enabled-payment-methods`, params);
  }

  calculatePrices(params: CalculateQuotePricesDto): Observable<QuotePricingCalculationDto> {
    return this.apiHttpClient.post<QuotePricingCalculationDto>(`${this.resourceUrl}/pricing/calculate`, params);
  }

  createEnquiry(body: QuoteUpsertDto) {
    return this.apiHttpClient.post<{ id: string }>(`${this.resourceUrl}/enquiry`, body);
  }
  createRevise(quoteId: string, body: QuoteUpsertDto) {
    return this.apiHttpClient.post<{ id: string }>(`${this.resourceUrl}/${quoteId}/revise`, body);
  }
  revisions(quoteId: string) {
    return this.apiHttpClient.get<QuoteRevisionDto[]>(`${this.resourceUrl}/${quoteId}/revisions`);
  }
  export(params?: GetQuotesParams): Observable<FileUtilResponse> {
    return FileUtil.fetchFile(this.httpClient, `${this.resourceUrl}/export`, params);
  }

  getActivities(quoteId: string): Observable<MmsQuoteActivity[]> {
    return this.apiHttpClient.get<MmsQuoteActivity[]>(`${this.resourceUrl}/${quoteId}/activities`);
  }

  createNote(quoteId: string, body: MmsQuoteNoteBody): Observable<void> {
    return this.apiHttpClient.post<void>(`${this.resourceUrl}/${quoteId}/note`, body);
  }
}
