import { Pipe, PipeTransform } from '@angular/core';
import { IBoatMovementPayload, MarinaMapColorStatus } from '@dm-workspace/types';
import { multihullOptions, berthsColors } from '../const/map-styles';

@Pipe({
  name: 'multihullPolygonStyleByStatus',
})
export class MultihullPolygonStyleByStatusPipe implements PipeTransform {
  transform(color: MarinaMapColorStatus, boatMovement?: IBoatMovementPayload): unknown {
    return { ...multihullOptions, ...berthsColors[color] };
  }
}
