import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit, signal } from '@angular/core';
import { Quote, QuoteCancelReason } from '@dm-workspace/types';
import { ConfirmModal } from '@dm-workspace/ui';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidatorService, FormUtils } from '@dm-workspace/forms';
import { finalize } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgForUtils } from '@dm-workspace/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'dm-mms-quote-action-cancel-modal',
  templateUrl: './mms-quote-action-cancel-modal.component.html',
  styleUrls: ['./mms-quote-action-cancel-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsQuoteActionCancelModalComponent extends ConfirmModal implements OnInit {
  @Input({ required: true }) public quote: Quote;
  @Input() public type: 'enquiry' | 'offer' = 'offer';
  protected readonly apiCalling = signal<boolean>(false);
  protected readonly offerApiService = inject(MmsQuotesApiService);
  protected readonly notification = inject(NotificationService);
  protected readonly apiValidator = inject(ApiValidatorService);
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly form = new FormGroup({
    reason: new FormControl<string | 'otherReason'>(null, [Validators.required]),
    otherReason: new FormControl<string>(null, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(100),
    ]),
  });
  protected readonly QuoteCancelReason = QuoteCancelReason;
  protected readonly NgForUtils = NgForUtils;

  constructor() {
    super();
  }

  public ngOnInit() {
    this.initOtherControlLogic();
  }

  private initOtherControlLogic() {
    const { otherReason, reason } = this.form.controls;
    otherReason.disable();

    reason.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((reason) => {
          if (reason === 'other') {
            otherReason.enable();
          } else {
            otherReason.disable();
          }
        })
      )
      .subscribe();
  }

  protected buttonConfirmClick() {
    if (this.apiCalling() || !FormUtils.isValid(this.form)) {
      return;
    }
    this.apiCalling.set(true);

    const { reason, otherReason } = this.form.value;
    const dto = {
      reason: otherReason ?? reason,
    };
    this.offerApiService
      .cancel(this.quote.quoteId, dto)
      .pipe(finalize(() => this.apiCalling.set(false)))
      .subscribe({
        next: () => {
          this.notification.openSuccess();
          this.onConfirm();
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }
}
