<form (ngSubmit)="submitForm()" [formGroup]="form">
  <dm-ui-modal>
    <h2 header>{{ "EDIT_RESOURCE_RESERVATION_GAP" | translate }}</h2>
    <div body>
      <div class="row gy-3 mt-2">
          <dm-form-group class="col-6">
            <label>{{ "GAP_START_DATE" | translate }}</label>
            <dm-form-date-time-picker dmForm formControlName="gapStart"
                                      [maxDate]="form.controls.gapEnd.value"
                                      [minDate]="reservation()?.fromDate"
            />
            <dm-form-error-message [control]="form.controls.gapStart" />
          </dm-form-group>

          <dm-form-group class="col-6">
            <label>{{ "GAP_END_DATE" | translate }}</label>
            <dm-form-date-time-picker
              dmForm
              formControlName="gapEnd"
              [minDate]="form.controls.gapStart.value"
              [maxDate]="reservation()?.toDate"
            />
            <dm-form-error-message [control]="form.controls.gapEnd" />
          </dm-form-group>
      </div>
    </div>

    <div footer class="d-flex justify-content-between align-items-center w-100">
      <button type="button" class="btn btn-tertiary btn-narrow" (click)="closeModal()">
        {{ "CANCEL" | translate }}
      </button>
      <button class="btn btn-primary btn-default" type="submit" [disabled]="formSubmitting()">
        <dm-ui-spinner [showSpinner]="formSubmitting()" class="me-2" />
        {{ "SAVE_GAP" | translate }}
      </button>
    </div>
  </dm-ui-modal>
</form>
