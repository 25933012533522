import {
  BoatDimensions,
  CustomerPrefilledData,
  IResourceBookingBoat,
  IResourceBookingOrder,
  IResourceBookingQuotation,
} from './resource-booking.interface';
import { Customer } from './customer.interface';

export type MmsJournalTaskStatName =
  | 'bookingRelated'
  | 'availabilityRelated'
  | 'expectedDepartures'
  | 'cruisesForVerification'
  | 'expectedArrivals';

export type MmsJournalTaskStats = Record<MmsJournalTaskStatName, MmsJournalTaskStat>;

export interface MmsJournalTaskStat {
  todoCount: number;
}

export enum MmsJournalTaskBookingStatusType {
  OVERSTAYED = 'overstayed',
  UNANNOUNCED_ARRIVAL = 'unannounced-arrival',
}

export type MmsJournalTaskBooking = IResourceBookingQuotation & {
  primaryOrder: IResourceBookingOrder;
  id: string;
  resource: {
    id: string;
    name: string;
  };
  humanReadableId: string;
  createdAt: string;
  boat: IResourceBookingBoat;
  boatDimensions: BoatDimensions;
  toDate: string;
  fromDate: string;
  customer: Customer;
  quotationPrefilledData?: CustomerPrefilledData;
  contractType: string;
  labels: string[];
  availableActions: string[];
  quoteId: string;
  quotationStatus: string;
  marina: string;
  statusType?: MmsJournalTaskBookingStatusType;
};
