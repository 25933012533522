<div class="container container-status">
  <dm-map-berth-status
    size="l"
    [name]="berth().data.berthName"
    [berthStatuses]="berth().getStatusesByViewType(viewType())"
  />
</div>
<div class="image-wrapper">
  <dm-map-tooltip-berth-dimensions [fullWidth]="true" [berthDimensions]="berth().dimensions" />
</div>
<div class="container reservations">
  @if (viewType() === viewTypes.reservations) {
    <dm-map-map-berth-details-reservations-list
      [berth]="berth()"
      [marinaCode]="marinaCode$ | async"
      (boatAction)="boatAction($event)"
    />
  } @else {
    <dm-map-map-berth-details-occupancy-list [berth]="berth()" (boatAction)="boatAction($event)" />
  }
  <div class="text-center w-100" *ngIf="showNewQQBtn()">
    <button type="button" class="btn btn-primary" (click)="goToQQ()">
      {{ "QUICK_QUOTE" | translate | uppercase }}
    </button>
  </div>
</div>
