<table class="table">
  <colgroup>
    <col>
    <col [style.width.px]="100">
    <col [style.width.px]="100">
    <col [style.width.px]="200">
  </colgroup>
  <thead>
    <tr>
      <th>{{"PRODUCTS" | translate}}</th>
      <th>{{"QUANTITY" | translate}}</th>
      <th>{{"UNIT" | translate}}</th>
      <th>{{"AMOUNT_WITH_TAX" | translate}}</th>
    </tr>
  </thead>
  <tbody>
  <tr *ngFor="let product of products">
    <td>{{product.marinaProduct.name}}</td>
    <td>{{product.pricing.duration}}</td>
    <td>{{product.marinaProduct.productUnit || ("DAYS" | translate)}}</td>
    <td>{{product.pricing.sumPriceGross | currency : product.pricing.currency}}</td>
  </tr>
  </tbody>
</table>
