import { IAllowedContactMethod } from './contact.interface';
import { CorporateType, CustomerSolvency, CustomerType } from '../backend';
import { Gender } from './common.interface';
import { CountryCode, LOCALIZATION_LANGUAGES } from '../constants';
import { ObReservationChannel } from './online-booking';
import { MarinaProductDto } from './marina.interface';

export interface Customer {
  id: string;
  accountNumber: string;
  type: CustomerType;
  corporateType: CorporateType;
  solvency: CustomerSolvency;
  companyName: string;
  displayName: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  stateOrProvinceId?: string;
  stateOrProvince?: ICountySimple;
  zip: string;
  country: string;
  countryCode?: CountryCode;
  countyId?: string;
  county?: ICountySimple;
  email: string;
  phone: string;
  fax: string;
  businessEmail: string;
  businessPhone: string;
  homePhone: string;
  otherPhone: string;
  language: LOCALIZATION_LANGUAGES;
  birthday: string;
  isActive: boolean;
  gender: Gender;
  fiscalCode?: string;
  nationality: string;
  passport: string;
  drivingLicense: string;
  profession: string;
  contactMethod: string;
  allowedContactMethod: IAllowedContactMethod;
  taxExemptNumber?: string;
  taxOffice?: string;
  invoiceData: InvoiceData;
  contactId: string;
  contact: ICustomerContact;
  customerPortalAccounts: MmsCustomerCpAccount[] | null;
}

export interface ICustomerContact {
  id: string;
  firstName: string;
  lastName: string;
  localFirstName: string;
  localLastName: string;
  countryCode: CountryCode;
  email: string;
  birthday: string;
  parentId: string;
  customer: string;
  type: string;
  language: LOCALIZATION_LANGUAGES;
  allowedContactMethod: ICustomerAllowedContactMethod;
  contactMethod: string;
  preferences: ICustomerPreferences;
  marketingAllowTracking: boolean;
  marketingMaterials: boolean;
  marketingOnlyInterest: boolean;
  address: string;
  city: string;
  country: string;
  state: string;
  fax: string;
  phone: string;
  zip: string;
  drivingLicense: string;
  nationality: string;
  passport: string;
  profession: string;
  countryTaxReference: string;
  taxExemptNumber: string;
  externalId: string;
  entitySource: string;
}

export interface ICustomerAllowedContactMethod {
  email: boolean;
  followingEmail: boolean;
  bulkEmail: boolean;
  phone: boolean;
  fax: boolean;
  mail: boolean;
}

export interface ICustomerPreferences {
  privacyPolicy: boolean;
  marketingContent: boolean;
  specialNeeds: ICustomerSpecialNeeds;
  mooringPreferences: ICustomerMooringPreferences;
}

export interface ICustomerSpecialNeeds {
  wheelchair: boolean;
  disabilityGolfCart: boolean;
}

export interface ICustomerMooringPreferences {
  mooringAssistant: boolean;
  bertCloseTo: ICustomerBertCloseTo;
}

export interface ICustomerBertCloseTo {
  sanitary: boolean;
  parking: boolean;
  office: boolean;
  restaurants: boolean;
  gasStation: boolean;
  exitOfMarina: boolean;
  other: boolean;
  otherMsg: string;
}

export interface IStateSimple {
  id: string;
  name: string;
}

export interface ICountySimple {
  id: string;
  name: string;
}

export interface InvoiceData {
  title?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}

export enum CustomerAccountSearch {
  ANY_CONTACT = 'ANY_CONTACT',
  ONLY_PRIMARY_CONTACT = 'ONLY_PRIMARY_CONTACT',
  NONE = 'NONE',
}

export type GetCustomersParams = {
  ids?: string[];
  email?: string;
  phone?: string;
  name?: string;
  countryCode?: string;
  offset?: number;
  limit?: number;
  type?: CustomerType;
  accountSearch?: CustomerAccountSearch;
  corporateType?: CorporateType;
  language?: LOCALIZATION_LANGUAGES;
};

export enum SearchMode {
  DIRECT = 'DIRECT',
  CONTACT = 'CONTACT',
}

export type SearchCustomersParams = {
  ids?: string[];
  search?: string;
  searchMode?: SearchMode;
  limit?: number;
  eligibleInMarina?: string;
};

export type CreateIndividualCustomerQueryParams = {
  validateForPayment?: boolean;
  marinaCode?: string;
  createCile?: boolean;
  forceCreation?: boolean;
  dryRun?: boolean;
};
export interface ICreateIndividualCustomer {
  city: string;
  zip: string;
  email: string;
  privateEmail?: string;
  businessEmail?: string;
  taxOffice?: string;
  language?: LOCALIZATION_LANGUAGES;
  countryCode: string;
  countyId?: string;
  firstName: string;
  lastName: string;
  birthday?: string;
  nationality?: string;
  phone?: string;
  drivingLicense?: string;
  profession?: string;
  address: string;
  state?: string;
  taxRegistrationType?: string;
  taxApplicabilityRule?: string;
  taxExemptNumber?: string;
  gender?: Gender;
  fiscalCode?: string;
}

export interface MmsCustomerCpAccount {
  contactId: string;
  hasAccount: boolean;
  isPrimaryContact: boolean;
}

export interface ICustomerShort {
  id: string;
  name: string;
  displayName: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  countryCode: string | null;
  type: CustomerType;
  phone: string | null;
}
export interface CustomerSendToExternalProcessingDto {
  fromDate: string;
  toDate: string;
  marinaCode: string;
  boatId: string;
}
export interface CustomerBookingReservePayload {
  fromDate: string;
  toDate: string;
  resourceId: string;
  comments?: string;
  boatId?: string;
  marinaCode: string;
  channel?: ObReservationChannel;
  products: MarinaProductDto[];
}
