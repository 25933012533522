import { ChangeDetectionStrategy, Component, forwardRef, inject, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MmsBoatsApiService } from '@dm-workspace/data-access';
import { IBoatDto } from '@dm-workspace/types';
import { map, Observable } from 'rxjs';
import { CustomSearchComponent } from '../customeSeach.class';
import { removeEmptyProperties } from '@dm-workspace/utils';

@Component({
  selector: 'dm-form-search-boat',
  templateUrl: './search-boat.component.html',
  styleUrl: './search-boat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchBoatComponent),
      multi: true,
    },
  ],
})
export class SearchBoatComponent extends CustomSearchComponent<IBoatDto> {
  @Input() public preferredCustomerId?: string;

  fetchItem(id: string): Observable<IBoatDto> {
    return this.#boatApiService.fetchById(id);
  }
  #boatApiService = inject(MmsBoatsApiService);
  fetchItems(search: string): Observable<IBoatDto[]> {
    const params = removeEmptyProperties({
      search,
      limit: 200,
      preferredCustomerId: this.preferredCustomerId,
    });

    return this.#boatApiService
      .searchBoats(params)
      .pipe(map((res) => res.sort((a, b) => (a.isHighlighted === b.isHighlighted ? 1 : -1))));
  }
}
