import { Pipe, PipeTransform } from '@angular/core';
import { QuoteStatus } from '@dm-workspace/types';
import { TagColor } from '@dm-workspace/ui';

@Pipe({
  name: 'quoteStatusTagColor',
})
export class MmsQuoteStatusTagColorPipe implements PipeTransform {
  public transform(quoteStatus: QuoteStatus): TagColor {
    if (!quoteStatus) {
      return;
    }

    switch (quoteStatus) {
      case QuoteStatus.ACCEPTED:
      case QuoteStatus.READY_TO_SEND:
        return 'green-light';
      case QuoteStatus.SENT:
        return 'orange-light';
      case QuoteStatus.NEW:
        return 'blue';
      case QuoteStatus.DRAFT:
        return 'grey-light';
      case QuoteStatus.DECLINED:
      case QuoteStatus.CANCELLED:
        return 'red-light';
      default:
        return 'grey';
    }
  }
}
