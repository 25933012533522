<!-- eslint-disable @angular-eslint/template/no-interpolation-in-attributes -->
<div class="flag-button-wrapper d-flex align-items-center">
  <div class="select-form">
    <ng-select
      class="phone"
      (ngModelChange)="onDialCodeChange($event)"
      [clearable]="false"
      notFoundText="{{ 'COUNTRY_NOT_FOUND' | translate }}"
      [(ngModel)]="selectedCountry"
      [disabled]="isDisabled()"
      dmFormSelectPhoneCountry
    >
      <ng-template ng-label-tmp ng-option-tmp let-country="item">
        <div class="d-flex align-items-center">
          <img
            src="{{
              'https://flagsapi.com/' +
                (country.code.includes('-') ? country.code.split('-')[1] : country.code) +
                '/flat/24.png'
            }}"
            alt="flag"
            class="me-1"
          />
          <span class="dial-code text-secondary fw-500">{{ country.dial_code }}</span>
        </div>
      </ng-template>
    </ng-select>
    <div class="separator"></div>
  </div>

  <div [ngClass]="selectedCountry.dial_code.length > 3 ? 'phone-form__code' : 'phone-form'">
    <input
      id="phone"
      class="phone-form__input"
      dmForm
      type="number"
      placeholder="{{ 'PHONE' | translate }}"
      max="999999999"
      min="0"
      oninput="this.value = this.value.slice(0, 9)"
      [disabled]="isDisabled()"
      [(ngModel)]="phoneNumber"
      (ngModelChange)="onPhoneNumberChange($event)"
    />
  </div>
</div>
