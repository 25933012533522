import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  Input,
  input,
  Output,
} from '@angular/core';
import { BerthAvailabilityReservationDto } from '@dm-workspace/types';
import { ACTION_TYPES, BoatActions } from '../action.model';
import { MapBerthClass } from '../../../berths.class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MmsBoatMovementChangeGapModalComponent } from '@dm-workspace/mms-panel/shared';
import { filter } from 'rxjs';
import { MapMmiComponentToken } from '../../map-mmi/map-mmi.component';

@Component({
  selector: 'dm-map-map-berth-details-reservations-list',
  templateUrl: './map-berth-details-reservations-list.component.html',
  styleUrl: './map-berth-details-reservations-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapBerthDetailsReservationsListComponent {
  #mapMmiComponent = inject(MapMmiComponentToken);
  #modalService = inject(NgbModal);
  berth = input.required<MapBerthClass>();
  hasItemsToShow = computed(() => this.berth().data.bookings.length);
  @Output() boatAction = new EventEmitter<BoatActions>();
  @Input() marinaCode: string;

  boatIsInOccupancy(boatId: string) {
    return this.berth().data.occupancy.some((value) => value.boatId === boatId);
  }
  temporaryAbsence(boatId: string) {
    return this.berth().data.temporaryAbsence.find((value) => value.boatId === boatId);
  }
  moveBoat(reservation: BerthAvailabilityReservationDto) {
    this.boatAction.emit({
      action: ACTION_TYPES.moveBoat,
      payload: {
        boat: reservation.boat,
        resourceId: reservation.resourceId,
      },
    });
  }

  transferBooking(reservation: BerthAvailabilityReservationDto) {
    this.boatAction.emit({
      action: ACTION_TYPES.transferBooking,
      payload: {
        boatId: reservation.boat.id,
        reservationId: reservation.reservationId,
        toDate: reservation.toDate,
        fromDate: reservation.fromDate,
        resource: {
          id: reservation.resourceId,
          name: this.berth().data.berthName,
        },
      },
    });
  }

  protected openEditGapModal(reservation: BerthAvailabilityReservationDto) {
    const { expectedReturnDate, movementOperationDate, movementId } = this.berth().data.temporaryAbsence.find(
      (value) => value.boatId === reservation.boat.id
    );
    const modal = this.#modalService.open(MmsBoatMovementChangeGapModalComponent, {
      size: 'lg',
    });
    const modalInstance = modal.componentInstance as MmsBoatMovementChangeGapModalComponent;

    modalInstance.form.patchValue({
      gapStart: movementOperationDate,
      gapEnd: expectedReturnDate,
    });
    modalInstance.movementId.set(movementId);
    modalInstance.reservation.set(reservation);

    modal.closed.pipe(filter(Boolean)).subscribe(() => {
      this.#mapMmiComponent.fetchBerthStatuses();
    });
  }
}
