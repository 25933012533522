<div class="modal-header">
  <h2 class="title">{{ "MMS_BOOKING_ENABLE_PRODUCTS_MODAL_.TITLE" | translate }}</h2>
</div>
<div class="modal-body">
  <p class="mb-4">
    {{ "MMS_BOOKING_ENABLE_PRODUCTS_MODAL_.SUBTITLE" | translate }}:
  </p>

  <ng-container *ngIf="fetchingAvailableMedia$ | async; else fetchedAvailableMedia">
    <div class="d-flex justify-content-center align-items-center" *ngIf="true" [style.height.px]="200">
      <dm-ui-spinner [showSpinner]="true" size="lg" [color]="'primary'"/>
    </div>
  </ng-container>

  <ng-template #fetchedAvailableMedia>
    <dm-mms-shared-booking-products-list [products]="bookingAvailableProducts$ | async"/>
    <p class="mt-4 text-center">
      <strong>
        {{ "MMS_BOOKING_ENABLE_PRODUCTS_MODAL_.TOTAL_PAYMENT" | translate | uppercase }}: {{ totalPaymentForProducts$ | async | currency : (currency$ | async) }}
      </strong>
    </p>
  </ng-template>
</div>

<div class="modal-footer justify-content-between d-flex">
  <button type="button" class="btn btn-tertiary btn-narrow" (click)="dismissModal()">
    {{ "CANCEL" | translate }}
  </button>
  <button
    class="btn btn-primary btn-default"
    type="submit"
    [disabled]="(fetchingAvailableMedia$ | async) || sendingRequest"
    (click)="onSubmit()"
  >
    <dm-ui-spinner class="me-2" [showSpinner]="sendingRequest"/>
    {{ "MMS_BOOKING_ENABLE_PRODUCTS_MODAL_.SEND_REQUEST" | translate }}
  </button>
</div>

