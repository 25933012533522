import { Injectable } from '@angular/core';
import { DateRange, Marina } from '@dm-workspace/types';
import { DateUtils } from '@dm-workspace/utils';

const MAX_DAYS_MARINAS: Record<string, number> = {
  IV04: 28,
};
@Injectable({
  providedIn: 'root',
})
export class BookingHelperService {
  readonly MIN_INQUIRY_DURATION = 333;
  readonly MAX_INQUIRY_DURATION = 365;

  isLongTerm(dateRange: DateRange, marinaCode?: string) {
    if (!dateRange?.toDate || !dateRange?.fromDate) {
      return false;
    }

    const fromDate = new Date(dateRange.fromDate);
    const toDate = new Date(dateRange.toDate);
    const dateRangeDiffTime = toDate.getTime() - fromDate.getTime();
    const daysDuration = Math.ceil(dateRangeDiffTime / DateUtils.ONE_DAY_TIME);
    const maxMarinaDays = marinaCode && MAX_DAYS_MARINAS[marinaCode];
    if ((maxMarinaDays && daysDuration > maxMarinaDays) || daysDuration >= this.MIN_INQUIRY_DURATION) {
      return true;
    }
    return false;
  }

  isInquiry(dateRange: DateRange, marina: Marina): boolean {
    if (!dateRange.toDate || !dateRange.fromDate) {
      return false;
    }
    const toDate = new Date(dateRange.toDate);
    const marinaBookingMaxDate = new Date(marina.additionalProperties.bookingMaxDate);

    if (toDate > marinaBookingMaxDate || this.isLongTerm(dateRange, marina.code)) {
      return true;
    }
    return false;
  }
}
