import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapMmiComponent } from './components/map-mmi/map-mmi.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { LibsSharedModule } from '@dm-workspace/shared';
import { NgxMoveableModule } from 'ngx-moveable';
import { RouterModule } from '@angular/router';
import { MapPopupRowComponent } from './components/marina-map-popup/map-popup-row.component';
import { MapPopupComponent } from './components/marina-map-popup/map-popup.component';
import {
  BerthPolygonStyleByBerthStatus,
  BerthPolygonStyleByStatusPipe,
} from './pipes/berth-polygon-style-by-status.pipe';
import { UiModule } from '@dm-workspace/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LibsFormsModule } from '@dm-workspace/forms';
import { BerthPolygonStyleMovementPipe } from './pipes/berth-polygon-style-movement.pipe';
import { BerthStatusesMapComponent } from './components/berth-statuses-map/berth-statuses-map.component';
import { BerthStatusModalComponent } from './components/berth-status-modal/berth-status-modal.component';
import { MapBerthStatusComponent } from './components/map-berth-status/map-berth-status.component';
import { MapTooltipBerthDimensionsComponent } from './components/map-tooltip-berth-dimensions/map-tooltip-berth-dimensions.component';
import { MapAlertsDrawerComponent } from './components/map-alerts-drawer/map-alerts-drawer.component';
import { MapDynamicLegendComponent } from './components/map-dynamic-legend/map-dynamic-legend.component';
import { MapBerthDetailsDrawerComponent } from './components/map-berth-details/map-berth-details-drawer.component';
import { MapBerthDetailsOccupancyListComponent } from './components/map-berth-details/map-berth-details-occupancy-list/map-berth-details-occupancy-list.component';
import { MapBerthDetailsReservationsListComponent } from './components/map-berth-details/map-berth-details-reservations-list/map-berth-details-reservations-list.component';
import { MapBerthDetailsBoatNameComponent } from './components/map-berth-details/map-berth-details-boat-name/map-berth-details-boat-name.component';
import { GetBoatTypeByBoatIdPipe } from './pipes/GetBoatTypeByBoatId.pipe';
import { MapTransformationsService } from '@dm-workspace/map-utils';
import { BerthsMapApiService } from '@dm-workspace/map-utils';
import { MapUtilsModule } from '@dm-workspace/map-utils';

@NgModule({
  imports: [
    CommonModule,
    LibsSharedModule,
    UiModule,
    GoogleMapsModule,
    RouterModule,
    MapUtilsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxMoveableModule,
    NgbTooltipModule,
    LibsFormsModule,
  ],
  providers: [MapTransformationsService, BerthsMapApiService],
  declarations: [
    MapMmiComponent,
    MapBerthDetailsDrawerComponent,
    MapAlertsDrawerComponent,
    MapPopupRowComponent,
    MapDynamicLegendComponent,
    MapPopupComponent,

    GetBoatTypeByBoatIdPipe,
    BerthPolygonStyleByStatusPipe,
    BerthPolygonStyleByBerthStatus,
    BerthStatusesMapComponent,
    BerthStatusModalComponent,
    MapBerthStatusComponent,
    MapTooltipBerthDimensionsComponent,
    BerthPolygonStyleMovementPipe,
    MapBerthDetailsOccupancyListComponent,
    MapBerthDetailsReservationsListComponent,
    MapBerthDetailsBoatNameComponent,
  ],
  exports: [
    MapMmiComponent,
    BerthPolygonStyleByBerthStatus,
    BerthStatusesMapComponent,
    MapTooltipBerthDimensionsComponent,
    BerthPolygonStyleMovementPipe,
  ],
})
export class MarinaMapModule {}
