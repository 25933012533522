import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { BerthDimensions, BerthStatusInMarina } from '@dm-workspace/types';

@Component({
  selector: 'dm-map-tooltip-berth-dimensions',
  templateUrl: './map-tooltip-berth-dimensions.component.html',
  styleUrls: ['./map-tooltip-berth-dimensions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapTooltipBerthDimensionsComponent {
  @Input() berthStatuses: BerthStatusInMarina[];
  @Input() berthName: string;
  @Input() berthDimensions?: BerthDimensions;

  @HostBinding('class.full-width')
  @Input()
  fullWidth?: boolean;
}
