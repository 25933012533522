import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
  selector: 'dm-ui-confirmation-icon',
  templateUrl: './confirmation-icon.component.html',
  styleUrls: ['./confirmation-icon.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationIconComponent {
  icon = input.required<'success' | 'error' | 'pending'>();
  size = input(56);
  iconUrl = computed(() => this.getIconUrlByType());

  getIconUrlByType() {
    switch (this.icon()) {
      case 'success':
        return '/assets/green-checkmark.svg';
      case 'error':
        return '/assets/ic-error-56.svg';
      case 'pending':
        return '/assets/ic-money-72.svg';
    }
  }
}
