<form #findForm="ngForm" [formGroup]="form" class="row gx-3 gy-3" (ngSubmit)="onSubmit()">
  <dm-form-group class="col-3">
    <label>{{ "MARINA" | translate }}</label>
    <ng-select
      [placeholder]="'CHOOSE_A_MARINA' | translate"
      dmForm
      [items]="marinas$ | async"
      bindLabel="name"
      bindValue="code"
      formControlName="marinaCode"
      [clearable]="false"
    />
    <dm-form-error-message controlName="marina"/>
  </dm-form-group>

  <dm-form-group class="col-3">
    <label>{{ "DATE_RANGE" | translate }}</label>
    <dm-form-range-date-picker [minDate]="minDate" dmForm formControlName="dateRange"/>
    <dm-form-error-message controlName="dateRange" />
  </dm-form-group>

  <dm-form-group class="col-6" *dmHasFeature="('' | features).berthReuse">
    <dm-form-checkbox
      class="checkbox-spacer"
      label="INCLUDE_BERTHS_FOR_WHICH_BOATS_ARE_ON_CRUISE"
      formControlName="searchInGaps"
    />
  </dm-form-group>

  <dm-ui-info-box
    *dmIsLongTerm="getDateRange()"
    class="pt-xxl mt-0"
    icon="info"
    text="LONG_TERM_BOOKING_INFO"
  />

  <div class="visual-line"></div>

  <div class="col-12" *ngIf="isBoatSelected_">
    <div class="row row-cols-5">
      <dm-ui-info-text [text]="form.value.boat?.name" [label]="'BOAT_NAME' | translate" />
      <dm-ui-info-text [text]="form.value.boatLength" [label]="'LENGTH_M' | translate" />
      <dm-ui-info-text [text]="form.value.boatWidth" [label]="'WIDTH_M' | translate" />
      <dm-ui-info-text [text]="form.value.boatDraft" [label]="'DRAFT_M' | translate" />
      <dm-ui-info-text [text]="form.value.boat.type" [label]="'TYPE' | translate" />
    </div>
  </div>

  <div class="col-12" *ngIf="!isBoatSelected_">
    <div class="row row-cols-5">
      <dm-form-group>
        <label>{{ "BOAT_NAME" | translate }}</label>
        <dm-form-select-typeahead
          typeahead="boats"
          [notFoundText]="'NO_BOATS_FOUND' | translate"
          bindLabel="name"
          formControlName="boat"
          [placeholder]="'BOAT_NAME' | translate"
        />
      </dm-form-group>

      <dm-form-group>
        <label>{{ "BOAT_TYPE" | translate }}</label>
        <ng-select
          dmForm
          [placeholder]="'BOAT_TYPE' | translate"
          [searchable]="false"
          formControlName="boatType"
        >
          <ng-option *ngFor="let option of (boatTypes | keyvalue)" [value]="option.value">{{ option.key | translate }}</ng-option>
        </ng-select>
        <dm-form-error-message controlName="boatType" />
      </dm-form-group>

      <dm-form-group>
        <label>{{ "LENGTH_M" | translate }}</label>
        <input dmForm formControlName="boatLength" type="number" min="0" placeholder="{{ 'LENGTH_M' | translate }}" />
        <dm-form-error-message controlName="boatLength"/>
      </dm-form-group>

      <dm-form-group>
        <label>{{ "WIDTH_M" | translate }}</label>
        <input dmForm formControlName="boatWidth" type="number" min="0" placeholder="{{ 'WIDTH_M' | translate }}" />
        <dm-form-error-message controlName="boatWidth"/>
      </dm-form-group>

      <dm-form-group>
        <label>{{ "DRAFT_M" | translate }}</label>
        <input dmForm formControlName="boatDraft" type="number" min="0" placeholder="{{ 'DRAFT_M' | translate }}" />
        <dm-form-error-message controlName="boatDraft"/>
      </dm-form-group>
    </div>
  </div>
</form>
