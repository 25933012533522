import {
  BerthAlertStatusInMarina,
  BerthMaintenanceStatusInMarina,
  BerthMapResponseDto,
  BerthOccupancyStatusInMarina,
  BerthReservationsStatusInMarina,
  BerthStatusInMarina,
  BoatMovementOperationForStatus,
  ContractType,
  IBoatAlertResponseDtoSeverityEnum,
  IBoatAlertResponseDtoStatusEnum,
  IBookingCalendarWithIndex,
  IMovement,
  MarinaMapColorStatus,
} from '@dm-workspace/types';
import { TagColor } from '@dm-workspace/ui';

export enum MapViewTypes {
  reservations,
  occupancy,
  alerts,
  statuses,
}

export const berthStatusToTagColor = (status: BerthStatusInMarina): TagColor => {
  switch (status) {
    case BerthReservationsStatusInMarina.reservedMidTerm:
    case BerthOccupancyStatusInMarina.expectedArrivals:
      return 'orange-light';
    case BerthOccupancyStatusInMarina.occupied:
      return 'green-light';
    case BerthOccupancyStatusInMarina.expectedDepartures:
      return 'magenta-light';
    case BerthAlertStatusInMarina.ongoingAlert:
    case BerthOccupancyStatusInMarina.overOccupied:
    case BerthReservationsStatusInMarina.overbooking:
      return 'red-light';
    case BerthAlertStatusInMarina.notUnderAlert:
    case BerthOccupancyStatusInMarina.empty:
    case BerthReservationsStatusInMarina.available:
      return 'black-light';
    case BerthReservationsStatusInMarina.reservedLongTerm:
      return 'dark-blue';
    case BerthAlertStatusInMarina.inProgressAlert:
    case BerthReservationsStatusInMarina.reservedTransient:
      return 'blue-light';

    case BerthMaintenanceStatusInMarina.disabled:
      return 'grey';
  }
};
// function datesDoNotOverlaps(toDateString: string, fromDateString: string) {
//   const toDate = new Date(toDateString);
//   const fromDate = new Date(fromDateString);
//   return !isSameDay(toDate, fromDate) || (getHours(fromDate) > 13 && getHours(toDate) < 13);
// }
export function getReservationMapStatus(berth: BerthMapResponseDto): BerthReservationsStatusInMarina[] {
  const alerts = [];
  if (berth.bookings?.length > 1) {
    // const hasNotAllowed = berth.bookings.some((booking) => {
    //   return berth.bookings
    //     .filter((value) => value.reservationId !== booking.reservationId)
    //     .every((innerBooking) => {
    //       return (
    //         innerBooking.reservationId !== booking.reservationId &&
    //         innerBooking.contractType !== ContractType.TRANSIENT &&
    //         booking.contractType !== ContractType.TRANSIENT &&
    //         datesDoNotOverlaps(booking.toDate, innerBooking.fromDate)
    //       );
    //     });
    // });
    // alerts.push(
    //   hasNotAllowed
    //     ? BerthReservationsStatusInMarina.unallowedOverbooking
    //     : BerthReservationsStatusInMarina.allowedOverbooking
    // );
    alerts.push(BerthReservationsStatusInMarina.overbooking);
  }
  if (berth.bookings.length === 1 && berth.bookings[0].contractType === ContractType.TRANSIENT) {
    alerts.push(BerthReservationsStatusInMarina.reservedTransient);
  }
  if (berth.bookings.length === 1 && berth.bookings[0].contractType === ContractType.MID_TERM) {
    alerts.push(BerthReservationsStatusInMarina.reservedMidTerm);
  }
  if (berth.bookings.length === 1 && berth.bookings[0].contractType === ContractType.LONG_TERM) {
    alerts.push(BerthReservationsStatusInMarina.reservedLongTerm);
  }
  // if (berth.temporaryAbsence.some((value) => value.operation === BoatMovementOperation.CRUISING)) {
  //   alerts.push(BerthReservationsStatusInMarina.temporaryAvailableCruise);
  // }
  // if (berth.temporaryAbsence.some((value) => value.operation === BoatMovementOperation.DRY_DOCK_LIFT)) {
  //   alerts.push(BerthReservationsStatusInMarina.temporaryAvailableDryDock);
  // }
  if (alerts.length) {
    return alerts;
  }
  return [BerthReservationsStatusInMarina.available];
}
export function getOccupancyMapStatus(berth: BerthMapResponseDto): BerthOccupancyStatusInMarina[] {
  const alerts = [];
  if (berth.occupancy?.length > 1) {
    alerts.push(BerthOccupancyStatusInMarina.overOccupied);
  }
  // if (berth.temporaryAbsence.some((value) => value.operation === BoatMovementOperation.CRUISING)) {
  //   alerts.push(BerthOccupancyStatusInMarina.temporaryAbsenceCruise);
  // }
  // if (berth.temporaryAbsence.some((value) => value.operation === BoatMovementOperation.DRY_DOCK_LIFT)) {
  //   alerts.push(BerthOccupancyStatusInMarina.temporaryAbsenceDryDock);
  // }
  if (berth.expectedDepartures.length) {
    alerts.push(BerthOccupancyStatusInMarina.expectedDepartures);
  }
  if (berth.expectedArrivals.length) {
    alerts.push(BerthOccupancyStatusInMarina.expectedArrivals);
  }
  if (berth.occupancy?.length === 1) {
    alerts.push(BerthOccupancyStatusInMarina.occupied);
    // alerts.push(
    //   berth.bookings.some((value) => value.boat?.id === berth.occupancy[0].boatId)
    //     ? BerthOccupancyStatusInMarina.occupiedWithBooking
    //     : BerthOccupancyStatusInMarina.occupiedWithoutBooking
    // );
  }
  if (alerts.length) {
    return alerts;
  }
  return [BerthOccupancyStatusInMarina.empty];
}
export function getAlertMapStatus(berth: BerthMapResponseDto): BerthAlertStatusInMarina[] {
  if (berth.alerts.length > 0) {
    if (
      berth.alerts.some(
        (alert) =>
          alert.severity !== IBoatAlertResponseDtoSeverityEnum.Warning &&
          alert.status === IBoatAlertResponseDtoStatusEnum.Active
      )
    ) {
      return [BerthAlertStatusInMarina.ongoingAlert];
    }
    if (
      berth.alerts.some(
        (alert) =>
          alert.severity !== IBoatAlertResponseDtoSeverityEnum.Warning &&
          alert.status === IBoatAlertResponseDtoStatusEnum.InProgress
      )
    ) {
      return [BerthAlertStatusInMarina.inProgressAlert];
    }
  }
  return [BerthAlertStatusInMarina.notUnderAlert];
}
export function getBerthStatusColor(booking: IBookingCalendarWithIndex): MarinaMapColorStatus {
  if (booking.overlapping) {
    return MarinaMapColorStatus.red;
  } else if (isTransient(booking.contractType)) {
    return MarinaMapColorStatus.blue;
  } else if (isNotOnMarina(booking.boatStatusInMarina)) {
    return MarinaMapColorStatus.yellow;
  } else if (isOnMarina(booking.boatStatusInMarina)) {
    return MarinaMapColorStatus.green;
  }
  return MarinaMapColorStatus.gray;
}

export function hasMovements(movements: IMovement[]): boolean {
  return movements && movements.length > 0;
}

export function isOnMarina(boatStatusInMarina: BoatMovementOperationForStatus): boolean {
  return [
    BoatMovementOperationForStatus.RETURN,
    BoatMovementOperationForStatus.ARRIVAL,
    BoatMovementOperationForStatus.DRY_DOCK_LAUNCH,
  ].includes(boatStatusInMarina);
}

export function isNotOnMarina(boatStatusInMarina: BoatMovementOperationForStatus, ignoreNull = false): boolean {
  let notOnMarinaStatuses: BoatMovementOperationForStatus[] = [
    BoatMovementOperationForStatus.CRUISING,
    BoatMovementOperationForStatus.DRY_DOCK_LIFT,
    BoatMovementOperationForStatus.DEPARTURE,
  ];

  if (!ignoreNull) {
    notOnMarinaStatuses = [...notOnMarinaStatuses, null];
  }
  return notOnMarinaStatuses.includes(boatStatusInMarina);
}

export function isTransient(contractType: ContractType): boolean {
  return contractType === ContractType.TRANSIENT;
}
