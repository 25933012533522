import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DownloadDirective } from './directives/download.directive';
import { FormatFileSizePipe } from './pipes/format-file-size.pipe';
import { TimestampPipe } from './pipes/timestamp.pipe';
import { BerthStatusPipe } from './pipes/berth-status.pipe';
import { MarinaNamePipe } from './pipes/marina-name.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { FullNamePipe } from './pipes/full-name.pipe';
import { EmptyPipe } from './pipes/empty.pipe';
import { DirectivesModule } from './directives/directives.module';
import { FindItemPipe } from './pipes/find-item.pipe';
import { UserStatusPipe } from './pipes/user-status.pipe';
import { FlagPipe } from './pipes/flag.pipe';
import { RoleNamePipe } from './pipes/role-name.pipe';
import { DateDiffPipe } from './pipes/date-diff.pipe';
import { JoinBerthsPipe } from './pipes/join-berths.pipe';
import { TotalOrderPricePipe } from './pipes/total-order-price.pipe';
import { StatusInMarina } from './pipes/status-in-marina.pipe';
import { GetMarinaByCodePipe } from './pipes/get-marina-by-code.pipe';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { HasPermissionDirective } from './directives/has-feature.directive';
import { FeaturesPipe } from './pipes/features.pipe';
import { ResourceLabelsTooltipPipe } from './pipes/resource-labels-tooltip.pipe';
import { IsNowBeforeDatesEndOfDay } from './pipes/is-now-before-dates-end-of-day.pipe';
import { TrackByPropertyPipe } from './pipes/track-by-property.pipe';
import { GetKeyFromEnumPipe } from './pipes/get-key-from-enum.pipe';
import { IconFromPylonMediaTypePipe } from './pipes/pylon-icon-from-type.pipe';
import { TrimPipe } from './pipes/trim.pipe';
import { BookingCurrencyPipe } from './pipes/booking-currency.pipe';
import { BookingTotalPricePipe } from './pipes/booking-total-price.pipe';
import { RouterLinkReturnUrlCurrentPathDirective } from './directives/router-link-return-url-current-path.directive';
import { CountryCodeTranslateKeyPipe } from './pipes/country-code-translate-key.pipe';
import { LanguageTranslateKeyPipe } from './pipes/langunage-translate-key.pipe';
import { IsBookingPastPipe } from './pipes/is-booking-past.pipe';
import { HasCustomerOnlineAccountPipe } from './pipes/has-customer-online-account.pipe';
import { GetCustomerTypeTranslateKeyPipe } from './pipes/get-customer-type-translate-key.pipe';
import { ContactTypeTranslateKeyPipe } from './pipes/contact-type-translate-key.pipe';
import { SortMarinasByCountryPipe } from './pipes/sort-marinas-by-country.pipe';
import { ChangeTimezonePipe } from './pipes/change-timezone.pipe';
import { SensorTypeTranslateKeyPipe } from './pipes/sensor-type-translate-key.pipe';
import { AlertStatusColorPipe } from './pipes/alert-status-color.pipe';
import { AlertStatusTranslateKeyPipe } from './pipes/alert-status-translate-key.pipe';
import { HtmlAttributeUrlValue } from './pipes/html-attribute-url-value';
import { MarinaProductNameTranslatePipe } from './pipes/marina-product-name-translate.pipe';
import { FormValidationChangeDirective } from './directives/form-validation-change.directive';
import { ArrayIncludesPipe } from './pipes/array-includes.pipe';
import { ModifyDateDaysPipe } from './pipes/modify-date-days.pipe';
import { ContractTypeTranslatePipe } from './pipes/contract-type-translate.pipe';
import { QuotationStatusTranslatePipe } from './pipes/quotation-status-translate.pipe';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { JournalTaskBookingStatusTypeTranslate } from './pipes/journal-task-booking-status-type-translate';
import { OrdinalPipe } from './pipes/ordinal.pipe';
import { IsBookingLongTermPipe } from './pipes/is-booking-long-term.pipe';
import { RouterLinkReturnUrlDirective } from './directives/router-link-return-url.directive';
import { QuoteCrmLinkPipe } from './pipes/quote-crm-link.pipe';
import { SingularPluralPipe } from './pipes/singular-plural.pipe';
import { YamlParsePipe } from './pipes/yaml-parse.pipe';
import { QuoteDescriptionBoatToBoat } from './pipes/quote-description-boat-to-boat.pipe';
import { GetStateByIdPipe } from './pipes/get-state-by-id.pipe';
import { GetCountyByIdPipe } from './pipes/get-county-by-id.pipe';
import { InitialsPipe } from './pipes/initials.pipe';
import { MaxBreakpointPipe, MinBreakpointPipe } from './pipes/breakpoint.pipe';

const declarations = [
  FormatFileSizePipe,
  EnumToArrayPipe,
  FullNamePipe,
  ResourceLabelsTooltipPipe,
  BerthStatusPipe,
  EmptyPipe,
  FeaturesPipe,
  FindItemPipe,
  CountryNamePipe,
  FlagPipe,
  TimestampPipe,
  HasPermissionDirective,
  MarinaNamePipe,
  DownloadDirective,
  LanguageTranslateKeyPipe,
  CountryCodeTranslateKeyPipe,
  UserStatusPipe,
  RoleNamePipe,
  DateDiffPipe,
  TotalOrderPricePipe,
  JoinBerthsPipe,
  StatusInMarina,
  GetMarinaByCodePipe,
  CustomCurrencyPipe,
  CustomDatePipe,
  IsNowBeforeDatesEndOfDay,
  TrackByPropertyPipe,
  GetKeyFromEnumPipe,
  IconFromPylonMediaTypePipe,
  TrimPipe,
  BookingTotalPricePipe,
  IsBookingPastPipe,
  BookingCurrencyPipe,
  RouterLinkReturnUrlCurrentPathDirective,
  FormValidationChangeDirective,
  SortMarinasByCountryPipe,
  ChangeTimezonePipe,
  HasCustomerOnlineAccountPipe,
  GetCustomerTypeTranslateKeyPipe,
  ContactTypeTranslateKeyPipe,
  AlertStatusColorPipe,
  AlertStatusTranslateKeyPipe,
  SensorTypeTranslateKeyPipe,
  HtmlAttributeUrlValue,
  MarinaProductNameTranslatePipe,
  ArrayIncludesPipe,
  ModifyDateDaysPipe,
  ContractTypeTranslatePipe,
  QuotationStatusTranslatePipe,
  JournalTaskBookingStatusTypeTranslate,
  OrdinalPipe,
  IsBookingLongTermPipe,
  RouterLinkReturnUrlDirective,
  QuoteCrmLinkPipe,
  SingularPluralPipe,
  YamlParsePipe,
  QuoteDescriptionBoatToBoat,
  GetStateByIdPipe,
  GetCountyByIdPipe,
  MaxBreakpointPipe,
  MinBreakpointPipe,
  InitialsPipe,
];

@NgModule({
  imports: [],
  providers: [CustomCurrencyPipe],
  declarations: [...declarations],
  exports: [...declarations, CommonModule, TranslateModule, DirectivesModule, NgbDropdownModule],
})
export class LibsSharedModule {}
