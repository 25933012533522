import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IBoatCaringReportDto,
  IBoat,
  IBoatCreatePayload,
  PostBoatMovementBody,
  IBoatDetails,
  IBoatDto,
  IBoatInsuranceUpdatePayload,
  IBoatMovement,
  IFetchBoatParams,
  IRequestParamsWithPagination,
  MARINA_URL_TOKEN,
  IBoatCaringReportParams,
} from '@dm-workspace/types';
import { ApiClientService } from '../api/api-client.service';
import { ApiCRUDResource } from '../classes/api-resource.class';

@Injectable({
  providedIn: 'root',
})
export class MmsBoatsApiService extends ApiCRUDResource<IBoatDto, IBoatDto, IBoatCreatePayload> {
  protected resourceUrl = `/mms/boats`;

  constructor(apiClientService: ApiClientService) {
    super(apiClientService);
  }

  fetchWithDetails(id: string): Observable<IBoatDetails> {
    return this.apiClientService.get(`${this.resourceUrl}/${id}/details`, {
      params: {
        marinaCode: MARINA_URL_TOKEN,
      },
    });
  }

  movement(id: string, body: PostBoatMovementBody): Observable<IBoatMovement> {
    body.marinaCode ??= MARINA_URL_TOKEN;
    return this.apiClientService.post(`${this.resourceUrl}/${id}/movement`, body);
  }

  verifyMovement(id: string, body: PostBoatMovementBody): Observable<string[]> {
    body.marinaCode ??= MARINA_URL_TOKEN;
    return this.apiClientService.post(`${this.resourceUrl}/${id}/movement/validate`, body);
  }

  getMovementHistory(boatId: string, params?: { marinaCode?: string }): Observable<IBoatMovement[]> {
    const marinaCode = params?.marinaCode || MARINA_URL_TOKEN;
    return this.apiClientService.get(`${this.resourceUrl}/${boatId}/movements`, {
      params: {
        marinaCode,
      },
    });
  }

  fetchBoats(params?: IRequestParamsWithPagination<IFetchBoatParams>): Observable<IBoatDto[]> {
    return this.apiClientService.get(`${this.resourceUrl}`, {
      params: { ...params },
    });
  }

  searchBoats(params?: IRequestParamsWithPagination<IFetchBoatParams>): Observable<IBoatDto[]> {
    return this.apiClientService.get(`${this.resourceUrl}/search`, {
      params: { ...params },
    });
  }

  caringReports(params?: IBoatCaringReportParams): Observable<IBoatCaringReportDto[]> {
    const marinaCode = params?.marinaCode || MARINA_URL_TOKEN;
    return this.apiClientService.get<IBoatCaringReportDto[]>(`${this.resourceUrl}/boat-caring-reports`, {
      params: { ...params, marinaCode },
    });
  }
  public updateInsurance(boatId: string, params: IBoatInsuranceUpdatePayload): Observable<IBoat> {
    return this.apiClientService.patch(`${this.resourceUrl}/insurance/${boatId}`, params);
  }
}
