import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Pagination } from './pagination';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dm-ui-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnDestroy {
  @Input() pagination?: Pagination;
  /**
   * @deprecated Use `pagination` instead
   */
  @Input() rows = 5;
  /**
   * @deprecated Use `pagination` instead
   */
  @Input() collectionSize = 5;
  /**
   * @deprecated Use `pagination` instead
   */
  @Input() maxPageButtons = 5;
  /**
   * @deprecated Use `pagination` instead
   */
  @Input() currentRow = 1;
  @Output() pageChange = new EventEmitter<number>();
  @Output() numberOfRowsChange = new EventEmitter<number>();
  public rowsPerPageOptions = [5, 10, 15, 20];
  sub: Subscription = new Subscription();

  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router
  ) {
    this.sub.add(
      this._activatedRoute.queryParams.subscribe((params) => {
        this.currentRow = params['page'] || 1;
        this.changePage(this.currentRow);
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onRowsChange($event: number): void {
    this.pagination?.changeLimit($event);
    this.currentRow = 1;
    this.numberOfRowsChange.emit($event);
  }

  changePage($event: number): void {
    this._router.navigate([], {
      relativeTo: this._activatedRoute,
      queryParams: {
        page: $event,
      },
      queryParamsHandling: 'merge',
    });
    this.pagination?.changePage($event);
    this.pageChange.emit($event);
  }
}
