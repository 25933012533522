import { inject, Injectable } from '@angular/core';
import {
  ENV,
  lngWithoutDialect,
  LOCALIZATION_LANGUAGES,
  MarinaCMS,
  MarinaCMSAnnualBenefits,
} from '@dm-workspace/types';
import { HttpClient } from '@angular/common/http';
import { ApiCmsResponse } from '../../../../types/src/libs/interfaces/api-cms.interface';
import { map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CmsMarinasApiService {
  protected readonly env = inject(ENV);
  #apiUrl = `${this.env.cmsApiUrl}marinas`;
  #httpClient = inject(HttpClient);
  list() {
    return this.#httpClient.get<ApiCmsResponse<MarinaCMS[]>>(this.#apiUrl, {
      params: {
        'populate%5Bfields%5D%5B0%5D': ['berthSettings'],
        'populate%marinaCountry%5D%5Bfields%5D%5B0%5D': ['slug', 'name'],
      },
    });
  }

  fetchByCode(code: string, lang = LOCALIZATION_LANGUAGES.ENGLISH) {
    return this.#httpClient.get<ApiCmsResponse<MarinaCMS[]>>(`${this.#apiUrl}`, {
      params: {
        locale: lngWithoutDialect(lang),
        'populate[berthSettings]': '*',
        'populate[contact]': '*',
        'populate[googleReviews][populate]': '*',
        'populate[mediaThumbnails][populate][images][populate]': '*',
        'populate[marinaHeader][populate]': 'keyFeatures',
        'filters[code][$eq]': code,
      },
    });
  }
  fetchById(id: string) {
    return this.#httpClient.get<ApiCmsResponse<MarinaCMS>>(`${this.#apiUrl}/${id}`, {
      params: {
        'populate[marina][fields][0]': 'code',
      },
    });
  }
  fetchMarina(id: string, lang = LOCALIZATION_LANGUAGES.ENGLISH): Observable<ApiCmsResponse<MarinaCMS>> {
    return this.fetchById(id).pipe(
      switchMap((value) =>
        this.fetchByCode(value.data.attributes.code, lang).pipe(
          map((value1) => ({ meta: value1.meta, data: value1.data[0] }))
        )
      )
    );
  }
  fetchAnnualBenefits(marinaCode: string, lang = LOCALIZATION_LANGUAGES.ENGLISH) {
    return this.#httpClient.get<ApiCmsResponse<MarinaCMSAnnualBenefits[]>>(
      `${this.env.cmsApiUrl}annual-benefits?locale=${lngWithoutDialect(
        lang
      )}&filters[marinas][code][$eq]=${marinaCode}&filters[productCode][$eq]=000021`,
      {
        headers: {
          authorization: `Bearer ${this.env.cmsKey}`,
        },
        params: {
          'populate[image]': '*',
        },
      }
    );
  }
}
