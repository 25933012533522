<ng-container [formGroup]="form()">
  <div class="row gy-3">
    <dm-form-group class="col-5">
      <label>{{ "MOVEMENT_TYPE" | translate }}</label>
      <ng-select [clearable]="false" dmForm formControlName="operation" (change)="onChangeOperation($event)">
        @for (boatMovementOperation of BoatMovementOperation | keyvalue: NgForUtils.originalOrder;
          track boatMovementOperation.key) {
          @if (operations() | arrayIncludes: boatMovementOperation.value) {
            <ng-option [value]="boatMovementOperation.value">
              {{ boatMovementOperation.key | boatMovementOperationTranslateKey | translate }}
            </ng-option>
          }
        }
      </ng-select>
      <dm-form-error-message [control]="form().controls.operation" />
    </dm-form-group>

    <div class="col-7">
      <div class="row">
        @if (form().controls.sourceId) {
          <dm-form-group class="col-6">
            <label>{{ sourceIdLabelTranslateKey$ | async | translate }}</label>
            <dm-form-select-typeahead
              dmForm
              typeahead="resources"
              formControlName="sourceId"
              bindLabel="name"
              bindValue="id"
              [resourceType]="sourceIdResourceType$ | async"
            />
            <dm-form-error-message [control]="form().controls.sourceId" />
          </dm-form-group>
        }

        @if (form().controls.destinationId) {
          <dm-form-group class="col-6">
            <label>{{ destinationIdLabelTranslateKey$ | async | translate }}</label>
            <dm-form-select-typeahead
              dmForm
              typeahead="resources"
              formControlName="destinationId"
              bindLabel="name"
              bindValue="id"
              [resourceType]="destinationIdResourceType$ | async"
            />
            <dm-form-error-message [control]="form().controls.destinationId" />
          </dm-form-group>
        }
      </div>
    </div>
  </div>

  <div class="row gy-3 mt-2">
    @if (form().controls.startsAt) {
      <dm-form-group class="col-6">
        <label>{{ startsAtLabelTranslateKey$ | async | translate }}</label>
        <dm-form-date-time-picker dmForm formControlName="startsAt" [maxDate]="todayDayString" />
        <dm-form-error-message [control]="form().controls.startsAt" />
      </dm-form-group>
    }

    @if (form().controls.endsAt) {
      <dm-form-group class="col-6">
        <label>{{ "RETURN_DATE" | translate }}</label>
        <dm-form-date-time-picker
          dmForm
          formControlName="endsAt"
          [defaultHour]="14"
          [minDate]="minReturnDate$ | async"
        />
        <dm-form-error-message [control]="form().controls.endsAt" />
      </dm-form-group>
    }

    @if (form().controls.allowsBerthReuse) {
      <div class="col-8">
        <dm-form-group>
          <label>
            <input
              type="checkbox"
              dmForm
              formControlName="allowsBerthReuse"
              (change)="onChangeAllowsBerthReuse($event)"
            />
            @switch (form().controls.operation.value) {
              @case (BoatMovementOperation.CRUISING) {
                {{ "BERTH_SHOULD_BOOKABLE_DURING_CRUISE" | translate }}
              }
              @default {
                {{ "BERTH_SHOULD_BOOKABLE_DURING_ABSENCE" | translate }}
              }
            }
          </label>
        </dm-form-group>
      </div>
    }
  </div>

  @if (form().controls?.allowsBerthReuse?.value) {
    <dm-ui-horizontal-line />

    <div class="row gy-3 mt-2">
      @if (form().controls.gapStart) {
        <dm-form-group class="col-6">
          <label>{{ "GAP_START_DATE" | translate }}</label>
          <dm-form-date-time-picker dmForm formControlName="gapStart" [defaultHour]="null"
                                    [minDate]="form().controls.startsAt.value"
                                    [maxDate]="form().controls.gapEnd.value"
          />
          <dm-form-error-message [control]="form().controls.gapStart" />
        </dm-form-group>
      }

      @if (form().controls.gapEnd) {
        <dm-form-group class="col-6">
          <label>{{ "GAP_END_DATE" | translate }}</label>
          <dm-form-date-time-picker
            dmForm
            formControlName="gapEnd"
            [defaultHour]="null"
            [maxDate]="form().controls.endsAt.value"
            [minDate]="form().controls.gapStart.value"
          />
          <dm-form-error-message [control]="form().controls.gapEnd" />
        </dm-form-group>
      }
    </div>
  }

</ng-container>
