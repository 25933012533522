import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClientService } from '../api/api-client.service';
import { ApiCRUDResource } from '../classes/api-resource.class';
import {
  Customer,
  Contact,
  ICreateIndividualCustomer,
  GetCustomersParams,
  PaginatedNormal,
  CreateIndividualCustomerQueryParams,
  SearchCustomersParams,
} from '@dm-workspace/types';

@Injectable({
  providedIn: 'root',
})
export class MmsCustomerApiService extends ApiCRUDResource<Customer> {
  protected readonly resourceUrl = '/mms/customers';

  constructor(apiClientService: ApiClientService) {
    super(apiClientService);
  }

  public fetchCustomers(params: GetCustomersParams): Observable<PaginatedNormal<Customer>> {
    return this.apiClientService.get<PaginatedNormal<Customer>>(this.resourceUrl, {
      params,
    });
  }

  public searchCustomers(params: SearchCustomersParams): Observable<PaginatedNormal<Customer>> {
    return this.apiClientService.get<PaginatedNormal<Customer>>(this.resourceUrl, {
      params,
    });
  }

  public update(id: string, data: Partial<Customer>): Observable<Customer> {
    return this.apiClientService.put<Customer>(`${this.resourceUrl}/${id}`, data);
  }

  public updateIndividual(id: string, data: Partial<Customer>): Observable<Customer> {
    return this.apiClientService.patch<Customer>(`${this.resourceUrl}/individual/${id}`, data);
  }

  public createIndividualCustomer(
    customer: ICreateIndividualCustomer,
    params: CreateIndividualCustomerQueryParams = {}
  ): Observable<Customer> {
    return this.apiClientService.post<Customer>(`${this.resourceUrl}/individual`, customer, {
      params,
    });
  }

  public createCorporateCustomer(customer: Customer): Observable<Customer> {
    return this.apiClientService.post<Customer>(`${this.resourceUrl}/corporate`, customer);
  }

  public fetchCustomerContacts(customerId: string): Observable<Contact[]> {
    return this.apiClientService.get<Contact[]>(`${this.resourceUrl}/${customerId}/contacts`);
  }

  public resetPassword(customerId: string, contactId: string): Observable<void> {
    return this.apiClientService.post(`${this.resourceUrl}/${customerId}/actions/reset-password/${contactId}`, null);
  }

  public setPrimaryContact(customerId: string, contactId: string): Observable<Contact[]> {
    return this.apiClientService.post(`${this.resourceUrl}/${customerId}/actions/set-primary-contact`, {
      contactId,
    });
  }

  public deleteContact(customerId: string, contactId: string): Observable<Contact[]> {
    return this.apiClientService.delete(`${this.resourceUrl}/${customerId}/actions/unlink-contact/${contactId}`);
  }
}
