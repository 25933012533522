import { Inject, Injectable } from '@angular/core';
import { API_PREFIX, ApiPrefix, LOCALIZATION_LANGUAGES } from '@dm-workspace/types';
import { getBrowseLocale, hasLngSupport } from '@dm-workspace/utils';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class TranslateCoreService {
  private localeFromSession?: LOCALIZATION_LANGUAGES = this.startLocale();

  constructor(
    private translateService: TranslateService,
    @Inject(API_PREFIX) private apiPrefix: ApiPrefix,
    @Inject(DOCUMENT) document: Document
  ) {
    if (this.apiPrefix === ApiPrefix.onlineBooking && this.translateService.defaultLang !== this.localeFromSession) {
      this.translateService.use(this.localeFromSession);
    }

    translateService.onLangChange.subscribe((event) => {
      document.documentElement.lang = event.lang;
    });
  }

  get localeKey(): string {
    return `${this.apiPrefix}_locale`;
  }

  get locale(): string {
    if (this.localeFromSession) {
      return this.localeFromSession;
    } else {
      this.localeFromSession =
        (sessionStorage.getItem(this.localeKey) as LOCALIZATION_LANGUAGES) || LOCALIZATION_LANGUAGES.ENGLISH;
      sessionStorage.removeItem(this.localeKey);
      return this.localeFromSession;
    }
  }
  getLngWithDialect() {
    const lng = this.locale;
    if (lng.includes('-')) {
      const slited = lng.split('-');
      return [slited[0], slited[1].toUpperCase()].join('_');
    }
    return [lng, lng.toUpperCase()].join('_');
  }
  getReferrer(): LOCALIZATION_LANGUAGES | undefined {
    const referrer = document.referrer;
    if (referrer && referrer.includes('dmarin')) {
      const strings = referrer.split('/');
      const lng = strings.find((st) => hasLngSupport(st));
      if (lng) {
        return lng as LOCALIZATION_LANGUAGES;
      }
    }
    return null;
  }

  startLocale(): LOCALIZATION_LANGUAGES {
    if (this.apiPrefix === ApiPrefix.onlineBooking) {
      return this.getReferrer() || getBrowseLocale();
    }
    return getBrowseLocale();
  }

  changeLng(lng: LOCALIZATION_LANGUAGES) {
    if (lng && hasLngSupport(lng)) {
      this.translateService.use(lng);
      const currentLng = this.locale;
      if (currentLng !== lng && this.apiPrefix === ApiPrefix.onlineBooking) {
        sessionStorage.setItem(this.localeKey, lng);
        window.location.reload();
      } else {
        this.localeFromSession = lng;
      }
    }
  }
}
