import { TemplateRef } from '@angular/core';

export type TNotificationType = 'success' | 'error';
type TPosition = 'top' | 'bottom';

export class NotificationConfig {
  public text?: string;
  public rawText?: string;
  public hideDefaultTitle?: boolean;
  public duration?: number = 6000;
  public type?: TNotificationType = 'success';
  public position?: TPosition = 'top';
  public rawContent?: string;
  public contentTpl?: TemplateRef<HTMLElement>;
  public content?: string;
  public closeOnClick? = true;
  public onCloseCallback?: () => void;
  public persistent? = false;

  constructor(config?: NotificationConfig) {
    if (!config) {
      return;
    }
    Object.assign(this, config);
  }
}
