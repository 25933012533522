import { Pipe, PipeTransform } from '@angular/core';

const sourceMap = {
  'Service Bus': 'CRM',
  Planner: 'Starflow (post-processing)',
  Starflow: 'Starflow (post-processing)',
} as const;

@Pipe({
  name: 'boatMovementSourceMap',
})
export class MmsBoatMovementSourceMapPipe implements PipeTransform {
  transform(source: string): string {
    return sourceMap[source as keyof typeof sourceMap] ?? source;
  }
}
