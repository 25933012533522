import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { FormUtils } from '@dm-workspace/forms';
import { catchError, throwError } from 'rxjs';
import { NotificationService } from '@dm-workspace/notification';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidator } from '@dm-workspace/shared';
import { finalize, tap } from 'rxjs/operators';
import { MmsQuoteMoveToWaitingListParams, Quote, QuoteCancelReason } from '@dm-workspace/types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgForUtils } from '@dm-workspace/utils';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'dm-mms-quote-move-to-waiting-list',
  templateUrl: './mms-quote-move-to-waiting-list-modal.component.html',
  styleUrls: ['./mms-quote-move-to-waiting-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsQuoteMoveToWaitingListModalComponent implements OnInit {
  #aModal = inject(NgbActiveModal);
  #quotesApi = inject(MmsQuotesApiService);
  #notificationService = inject(NotificationService);
  #destroyRef = inject(DestroyRef);

  #validUntilInitValue = (() => {
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + 14);
    return todayDate.toISOString().split('T')[0];
  })();

  readonly quote = signal<Quote>(null);
  protected todayDate = new Date();
  protected readonly formSubmitting = signal<boolean>(false);
  protected readonly form = new FormGroup({
    notifyUser: new FormControl<boolean>(true),
    validUntil: new FormControl<string>(this.#validUntilInitValue, [Validators.required]),
    reason: new FormControl<QuoteCancelReason | 'other'>(null, [Validators.required]),
    otherReason: new FormControl<string>(null, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(100),
    ]),
  });

  ngOnInit() {
    this.#initOtherControlLogic();
  }

  #initOtherControlLogic() {
    const { otherReason, reason } = this.form.controls;
    otherReason.disable();

    reason.valueChanges
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        tap((reason) => {
          if (reason === 'other') {
            otherReason.enable();
          } else {
            otherReason.disable();
          }
        })
      )
      .subscribe();
  }

  protected submitForm() {
    if (this.formSubmitting() || !FormUtils.isValid(this.form)) {
      return;
    }
    this.formSubmitting.set(true);

    const { reason, otherReason, ...restFormValue } = this.form.getRawValue();

    const dto: MmsQuoteMoveToWaitingListParams = {
      reason: otherReason ?? reason,
      ...restFormValue,
    };

    this.#quotesApi
      .moveToWaitingList(this.quote().quoteId, dto)
      .pipe(
        catchError((res: HttpErrorResponse) => {
          this.#notificationService.openError(ApiValidator.getApiNotificationError(res));
          return throwError(() => res);
        }),
        finalize(() => this.formSubmitting.set(false)),
        tap(() => {
          this.#notificationService.openSuccess();
          this.#aModal.close(true);
        })
      )
      .subscribe();
  }

  protected closeModal() {
    this.#aModal.close(false);
  }

  protected readonly NgForUtils = NgForUtils;
  protected readonly QuoteCancelReason = QuoteCancelReason;
}
