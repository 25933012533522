import { ChangeDetectionStrategy, Component, inject, Input, OnInit, signal } from '@angular/core';
import { Contact, Quote } from '@dm-workspace/types';
import { ConfirmModal } from '@dm-workspace/ui';
import { MmsQuotesApiService } from '@dm-workspace/data-access';
import { NotificationService } from '@dm-workspace/notification';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiValidatorService } from '@dm-workspace/forms';
import { finalize } from 'rxjs';
import { MmsConfirmOfferModal } from '../mms-offer-modal.interface';
import { map, tap } from 'rxjs/operators';
import { MmsCustomerService } from '@dm-workspace/mms-panel/shared';
import { MmsOfferActionSendTarget } from '../mms-offer-action-send-modal/mms-offer-action-send-modal.component';

@Component({
  selector: 'dm-mms-offers-action-send-remainder-modal',
  templateUrl: './mms-offer-action-send-remainder-modal.component.html',
  styleUrls: ['./mms-offer-action-send-remainder-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmsOfferActionSendRemainderModalComponent extends ConfirmModal implements MmsConfirmOfferModal, OnInit {
  @Input({ required: true }) public offer: Quote;
  @Input({ required: true }) public target: MmsOfferActionSendTarget;
  protected readonly apiCalling = signal<boolean>(false);
  protected readonly offerApiService = inject(MmsQuotesApiService);
  protected readonly customerService = inject(MmsCustomerService);
  protected readonly notification = inject(NotificationService);
  protected readonly apiValidator = inject(ApiValidatorService);
  protected readonly customerPrimaryContact = signal<Contact | null>(null);

  constructor() {
    super();
  }

  public ngOnInit() {
    this.customerService
      .getCustomerPrimaryContact(this.offer.customer.id)
      .pipe(
        map(({ customerPrimaryContact }) => customerPrimaryContact),
        tap((customerPrimaryContact) => this.customerPrimaryContact.set(customerPrimaryContact))
      )
      .subscribe();
  }

  protected buttonConfirmClick() {
    this.apiCalling.set(true);

    this.offerApiService
      .remindCustomer(this.offer.quoteId, { target: this.target })
      .pipe(finalize(() => this.apiCalling.set(false)))
      .subscribe({
        next: () => {
          this.notification.openSuccess();
          this.onConfirm();
        },
        error: (res: HttpErrorResponse) => {
          const notificationContent = this.apiValidator.getApiNotificationError(res);
          this.notification.openError(notificationContent);
        },
      });
  }
}
